<template>
  <main style="padding: 24px"
        class="cookie-policy-onboarding"
  >
    <Row v-if="step === 0">
      <Row justify="center"
           type="flex"
      >
        <Row>
          <p>
            Welcome to your Cookie Policy Manager. <br><br>
            Here, You are able to manage and classify your cookies ready to
            publish.
            <br><br>
            You will the ability to exempt a specific trackers and disclose the
            purpose of cookies
          </p>
          <Alert type="warning"
                 show-icon
          >
            Provided metrics are related to the selected period
          </Alert>
          <img
            :src="step1"
            style="width:100%; margin:42px auto; border:1px solid rgba(0,0,0,0.2)"
          >
        </Row>
      </Row>
    </Row>
    <Row v-if="step === 1">
      <Row>
        <p>Clicking on one of the indicators will display additional data</p>
        <p>
          For example, clicking on the "Unqualified trackers" indicator
          will display a detailed list of trackers that are active on your
          properties but don’t have disclosed purposes
        </p>
        <img
          :src="step2"
          style="width:100%; margin:42px auto; border:1px solid rgba(0,0,0,0.2)"
        >
      </Row>
    </Row>
    <Row v-if="step === 2">
      <Row>
        <p>
          To add more precision you can also search a specific tracker or add multiple filter dimensions.
        </p>
        <img
          :src="step3"
          style="width:100%; margin:42px auto; border:1px solid rgba(0,0,0,0.2)"
        >
      </Row>
    </Row>
    <Row v-if="step === 3">
      <Col span="24"
           style="margin-bottom:24px"
      >
        <p>Disclose one or several purposes to a tracker by clicking on it</p>
      </Col>

      <Col span="14">
        <Row>
          <p>That will open a right side panel displaying general information about the tracker </p>
          <figure>
            <!-- <img
              :src="step2_2"
              style="
            width: 100%;
            max-width: 900px;
            margin: 1px auto;
          "
            > -->
          </figure>

          <p>
            Click on the button to act on the disclosure
          </p>
        </Row>
      </Col>
      <Col span="10">
        <img
          :src="step4_2"
          style="width:100%; border:1px solid rgba(0,0,0,0.2)"
        >
      </Col>
    </Row>
    <Row v-if="step === 4"
         :gutter="24"
    >
      <Col span="14">
        <Row style="height:inherit!important">
          <p style="margin-bottom:24px">
            You can also act on the exemption statut about a particular tracker
          </p>
          <p style="margin-bottom:24px">
            Depending on the purposes disclosed you may have the possibility to exempt a tracker.
          </p>
          <p style="margin-bottom:24px">
            The exemption must be accompanied by a justification in accordance with the principles recommended by the CNIL
          </p>
        </Row>
      </Col>
      <Col span="10">
        <figure>
          <img
            :src="step8"
            style="width:100%; border:1px solid rgba(0,0,0,0.2)"
          >
        </figure>
      </Col>
    </Row>
    <Row v-if="step === 5">
      <Row>
        <p>
          To export your Cookie Policy Project, click on the button
        </p>
        <figure style="max-width:600px">
          <img
            :src="step6_1"
            style="width:100%; margin:42px auto; border:1px solid rgba(0,0,0,0.2)"
          >
        </figure>
        <p>This will provide an overviewof the modifications</p>
        <figure style="max-width:600px">
          <img
            :src="step6_2"
            style="width:100%; margin:42px auto; border:1px solid rgba(0,0,0,0.2)"
          >
        </figure>
      </Row>
    </Row>
    <Row v-if="step === 6">
      <Row type="flex">
        <Col span="24">
          <Row justify="space-around">
            <p>Finally you will have publish the cookie policy list on your website</p>
          </Row>
        </Col>
        <Col span="24">
          <Row type="flex"
               justify="space-around"
          >
            <figure style="max-width:600px">
              <img
                :src="step6_1"
                style="width:100%; margin:42px auto; border:1px solid rgba(0,0,0,0.2)"
              >
            </figure>
            <figure style="max-width:600px">
              <img
                :src="step6_2"
                style="width:100%; margin:42px auto; border:1px solid rgba(0,0,0,0.2)"
              >
            </figure>
          </Row>
        </Col>
        <Col span="24">
          <Row justify="space-around">
            <p>
              During the next scans, Facettes will display the new cookies and trackers active and un qualified on your website.
            </p>
          </Row>
        </Col>
      </Row>
    </Row>
    <Row type="flex"
         style="margin-top: 24px"
    >
      <Steps :current="step"
             style="width: 300px; margin: 0 auto"
      >
        <Step v-for="(_, index) in 6"
              :key="index"
              style="cursor: pointer"
              @click.native="step = index"
        />
      </Steps>
      <Button
        type="primary"
        style="position: absolute; bottom: 15px; right: 15px"
        @click="step === 6 ? $emit('close-onboarding-modal') : next()"
      >
        {{ step === 5 ? "Done" : "Next step" }}
      </Button>
    </Row>
  </main>
</template>
<script>
import step1 from "@/assets/images/cookie_policy/step_1.png"
import step2 from "@/assets/images/cookie_policy/step_2.png"
import step3 from "@/assets/images/cookie_policy/step_3.png"
import step4_2 from "@/assets/images/cookie_policy/step_4_2.png"
import step5 from "@/assets/images/cookie_policy/step_5.png"
import step6 from "@/assets/images/cookie_policy/step_6.png"
import step6_1 from "@/assets/images/cookie_policy/step_6_1.png"
import step6_2 from "@/assets/images/cookie_policy/step_6_2.png"
import step7 from "@/assets/images/cookie_policy/step_7.png"
import step8 from "@/assets/images/cookie_policy/step_8.png"
export default {
  name: "OnboadingModal",
  data() {
    return {
      step: 0,
      step1,
      step2,
      step3,
      step4_2,
      step5,
      step6,
      step6_1,
      step6_2,
      step7,
      step8
    }
  },
  methods: {
    next() {
      if (this.step == 5) {
        this.step = 0
      } else {
        this.step += 1
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.cookie-policy-onboarding {
  &div:first-child {
    height: 70vh;
  overflow: auto;
  }
  p {
    font-size:14px;
  }
}
</style>

<template>
  <div class="contentWrapper">
    <Alert type="warning"
           show-icon
           v-if="discloseIntcf"
    >
      This vendor has declared purposes and features through IAB TCF v2 under vendor ID {{ trackerProps.vendor.tcfv2_id }}.
      <br>
      Only their declared purposes are available for selection.
    </Alert>

    <Row>
      <Col span="24">
        <p v-if="!discloseIntcf && !selectedPurposes.includes(1)">
          Qualify purposes of this tracker to add it to your Cookie Policy
        </p>
        <!-- <p v-if="selectedPurposes.includes(1)">
            The selected purposes do not allow you to exempt the tracker.<br>
            To understand the relationship between purpose and exemption go to the <a target="_blank">help center page</a>
          </p> -->
      </Col>
    </Row>

    <Row type="flex"
         :gutter="12"
    >
      <Col style="display:flex;flex-direction:column; align-items: center">
        <input type="checkbox"
               v-for="(item) in purposes"
               :key="'purpose' + item.id + 1"
               style="margin-top:10px"
               :id="item.id + 1"
               :value="item.id"
               :disabled="isTcfAndCurrentPurposeIsNotDeclared(item.id)"
               v-model="selectedPurposes"
        >
      </Col>
      <Col style="display:flex;flex-direction:column; align-items: center">
        <ul>
          <li v-for="(val, key) of purposes"
              :key="key + 1"
              :style="{marginTop:'12px', paddingLeft:'12px', color: isTcfAndCurrentPurposeIsNotDeclared(val.id)?'lightgray':'inherit'}"
          >
            {{ `${key + 1} - ${val.name}` }}
          </li>
        </ul>
      </Col>
    </Row>

    <Row>
      <Col style="margin-top: 48px;width:100%;">
        <Button type="primary"
                @click="$emit('qualify-tracker', {tracker: selectedTracker, purposes: selectedPurposes})"
                style="width:150px; display:block;text-align:center;margin:0 auto;"
        >
          Save and close
        </Button>
      </Col>
    </Row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PurposeList',
  data () {
    return {
      selectedPurposes: [],
      selectedTracker: null,
      tcf_vendor_declared_purposes: [],
      discloseIntcf: null
    }
  },
  props: {
    trackerProps: {
      note: 'the clicked tracker',
      default: null,
      type: Object
    }
  },
  watch: {
    trackerProps:{
      deep:true,
      immediate: true,
      handler: function (val, _) {
        this.selectedTracker = val
        this.handleSelectedPurposeIfDiscloseInTcf(val)
      }
    },
    selectedPurposes: {
      deep: true,
      handler: function (val, _) {
        this.$emit('purposes-sent', val)
      }
    }
  },
  methods: {
    handleSelectedPurposeIfDiscloseInTcf (tracker) {
      if (tracker?.vendor?.tcfv2_id || tracker?.vendor?.iabv2Id) {
        this.discloseIntcf= true
      } else {
        this.discloseIntcf = false
      }
      const purposes = tracker?.purposes || tracker?.vendor?.iabvendor_tcfv2_f_and_p?.purposes || []
      if ( this.selectedKey !== 'qualified' && !purposes.includes(1)) purposes.push(1)

      this.selectedPurposes = purposes
      this.tcf_vendor_declared_purposes = tracker?.vendor?.iabvendor_tcfv2_f_and_p?.purposes
      this.$emit('purposes-sent', purposes)
    },
    isTcfAndCurrentPurposeIsNotDeclared (purpose_id) {
      return !!this.tcf_vendor_declared_purposes && this.tcf_vendor_declared_purposes.length && !this.selectedTracker?.vendor?.iabvendor_tcfv2_f_and_p?.purposes.includes(purpose_id)
    },
    // textColor (item) {
    //   console.log(!!this.tcf_vendor_declared_purposes && this.tcf_vendor_declared_purposes.length && !this.selectedTracker?.vendor?.iabvendor_tcfv2_f_and_p?.purposes.includes(item.id))
    //   return !!this.selectedTracker?.vendor?.iabvendor_tcf_f_and_p && !this.selectedTracker?.vendor?.iabvendor_tcf_f_and_p?.purposes.includes(item.id)
    //     ? 'grey'
    //     : 'inherit'
    // },
  },
  computed: {
    ...mapGetters({
      selectedKey: 'cookie/getSeletedCardKey'
    }),
    isValidButtonDisabled () {
      if (this.discloseIntcf) return true
      if (!this.discloseIntcf && !this.selectedPurposes.length) return true
      return false
    },

    purposes() {
      const amount = 11
      const purposes = []
      for (let k = 1; k<amount; k++) {
        const i18nKey = 'global.tcfv2_f_and_p.purposes.name_p_' + k
        purposes.push({
          id: k,
          name: this.$t(i18nKey)
        })
      }
      return purposes
    }
  }
}
</script>

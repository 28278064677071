var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.displayAddButton ? _c('main', {
    staticClass: "map-cookie-vendor"
  }, [_c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" A tracker requires association with a vendor. Please select the corresponding vendor from our list or name it if not present in our vendor base ")]), _c('Row', {
    staticClass: "container-1",
    attrs: {
      "type": "flex",
      "justify": "space-around"
    }
  }, [!_vm.shouldConfirm ? _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('Row', {
    staticStyle: {
      "width": "110%"
    },
    attrs: {
      "type": "flex"
    }
  }, [_c('Input', {
    staticStyle: {
      "max-width": "175px",
      "display": "block",
      "margin-right": "12px"
    },
    attrs: {
      "type": "text",
      "suffix": "ios-search",
      "placeholder": "Vendor name"
    },
    on: {
      "input": _vm.debounceInput
    },
    model: {
      value: _vm.inputName,
      callback: function callback($$v) {
        _vm.inputName = $$v;
      },
      expression: "inputName"
    }
  }), !_vm.searchVendors.length && _vm.inputName.length ? _c('Button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.selectVendor(false, null);
      }
    }
  }, [_vm._v(" Add vendor ")]) : _vm._e()], 1)], 1) : _vm._e(), !_vm.shouldConfirm ? _c('Col', {
    attrs: {
      "span": "14"
    }
  }, [_vm.searchVendors.length ? _c('ul', {
    staticStyle: {
      "width": "175px",
      "margin-left": "-12px"
    }
  }, _vm._l(_vm.searchVendors, function (val, key) {
    return _c('li', {
      key: key,
      staticClass: "list-item",
      on: {
        "click": function click($event) {
          return _vm.selectVendor(false, val);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.ellipsis(val.name)) + " "), _c('Icon', {
      attrs: {
        "type": "md-add"
      }
    })], 1);
  }), 0) : _vm._e()]) : _vm._e(), _vm.shouldConfirm ? _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_vm._v(" Do you want to confirm that the Initiator Vendor is : "), _c('strong', [_vm._v(_vm._s(_vm.linkedVendorAlias || _vm.linkedVendor.name))])]), _c('Button', {
    attrs: {
      "type": "warning"
    },
    on: {
      "click": _vm.resetSearch
    }
  }, [_vm._v(" Choose another vendor ")]), _c('Button', {
    staticStyle: {
      "margin-left": "15px"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.selectVendor(true);
      }
    }
  }, [_vm._v(" Confirm ")])], 1) : _vm._e()], 1), _c('Divider')], 1) : _c('div', [_c('i', {
    staticClass: "far fa-edit",
    staticStyle: {
      "font-size": "14px",
      "font-weight": "bold",
      "cursor": "pointer",
      "position": "absolute",
      "left": "120px",
      "top": "32px",
      "colo": "#495060"
    },
    on: {
      "click": function click($event) {
        _vm.displayAddButton = true;
      }
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }
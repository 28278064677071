import moment from "moment"

const triggerDownload = function ({ blob, title = "" }) {
  const formattedTitle = `${moment().format("LL")}_${title}`

  console.log("formattedTitle", formattedTitle)

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, formattedTitle)
  } else {
    const link = document.createElement("a")
    if (link.download !== undefined) {
      console.log("I am in link.download")
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", formattedTitle)
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

const replacer = (key, value) => {
  if(Array.isArray(value)){
    value = value.join(",")
  }
  return(value === null ? "" : value)
}

export const downloadFile = function ({
  list = [],
  title = "",
  extension = "CSV",
  headers = [],
}) {
  let titleWithExtension = `${title}.json`,
    blob = new Blob([JSON.stringify(list)], { type: "application/json" })

  if (extension === "CSV") {
    const csv = [
      headers.join(","),
      ...list.map((row) =>
        headers
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n")

    titleWithExtension = `${title}.csv`
    blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
  }

  triggerDownload({ blob, title: titleWithExtension })
}

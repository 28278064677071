var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "cookie-policy-onboarding",
    staticStyle: {
      "padding": "24px"
    }
  }, [_vm.step === 0 ? _c('Row', [_c('Row', {
    attrs: {
      "justify": "center",
      "type": "flex"
    }
  }, [_c('Row', [_c('p', [_vm._v(" Welcome to your Cookie Policy Manager. "), _c('br'), _c('br'), _vm._v(" Here, You are able to manage and classify your cookies ready to publish. "), _c('br'), _c('br'), _vm._v(" You will the ability to exempt a specific trackers and disclose the purpose of cookies ")]), _c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" Provided metrics are related to the selected period ")]), _c('img', {
    staticStyle: {
      "width": "100%",
      "margin": "42px auto",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.step1
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.step === 1 ? _c('Row', [_c('Row', [_c('p', [_vm._v("Clicking on one of the indicators will display additional data")]), _c('p', [_vm._v(" For example, clicking on the \"Unqualified trackers\" indicator will display a detailed list of trackers that are active on your properties but don’t have disclosed purposes ")]), _c('img', {
    staticStyle: {
      "width": "100%",
      "margin": "42px auto",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.step2
    }
  })])], 1) : _vm._e(), _vm.step === 2 ? _c('Row', [_c('Row', [_c('p', [_vm._v(" To add more precision you can also search a specific tracker or add multiple filter dimensions. ")]), _c('img', {
    staticStyle: {
      "width": "100%",
      "margin": "42px auto",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.step3
    }
  })])], 1) : _vm._e(), _vm.step === 3 ? _c('Row', [_c('Col', {
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('p', [_vm._v("Disclose one or several purposes to a tracker by clicking on it")])]), _c('Col', {
    attrs: {
      "span": "14"
    }
  }, [_c('Row', [_c('p', [_vm._v("That will open a right side panel displaying general information about the tracker ")]), _c('figure'), _c('p', [_vm._v(" Click on the button to act on the disclosure ")])])], 1), _c('Col', {
    attrs: {
      "span": "10"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "100%",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.step4_2
    }
  })])], 1) : _vm._e(), _vm.step === 4 ? _c('Row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('Col', {
    attrs: {
      "span": "14"
    }
  }, [_c('Row', {
    staticStyle: {
      "height": "inherit!important"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v(" You can also act on the exemption statut about a particular tracker ")]), _c('p', {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v(" Depending on the purposes disclosed you may have the possibility to exempt a tracker. ")]), _c('p', {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v(" The exemption must be accompanied by a justification in accordance with the principles recommended by the CNIL ")])])], 1), _c('Col', {
    attrs: {
      "span": "10"
    }
  }, [_c('figure', [_c('img', {
    staticStyle: {
      "width": "100%",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.step8
    }
  })])])], 1) : _vm._e(), _vm.step === 5 ? _c('Row', [_c('Row', [_c('p', [_vm._v(" To export your Cookie Policy Project, click on the button ")]), _c('figure', {
    staticStyle: {
      "max-width": "600px"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "100%",
      "margin": "42px auto",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.step6_1
    }
  })]), _c('p', [_vm._v("This will provide an overviewof the modifications")]), _c('figure', {
    staticStyle: {
      "max-width": "600px"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "100%",
      "margin": "42px auto",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.step6_2
    }
  })])])], 1) : _vm._e(), _vm.step === 6 ? _c('Row', [_c('Row', {
    attrs: {
      "type": "flex"
    }
  }, [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('Row', {
    attrs: {
      "justify": "space-around"
    }
  }, [_c('p', [_vm._v("Finally you will have publish the cookie policy list on your website")])])], 1), _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('Row', {
    attrs: {
      "type": "flex",
      "justify": "space-around"
    }
  }, [_c('figure', {
    staticStyle: {
      "max-width": "600px"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "100%",
      "margin": "42px auto",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.step6_1
    }
  })]), _c('figure', {
    staticStyle: {
      "max-width": "600px"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "100%",
      "margin": "42px auto",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.step6_2
    }
  })])])], 1), _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('Row', {
    attrs: {
      "justify": "space-around"
    }
  }, [_c('p', [_vm._v(" During the next scans, Facettes will display the new cookies and trackers active and un qualified on your website. ")])])], 1)], 1)], 1) : _vm._e(), _c('Row', {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "type": "flex"
    }
  }, [_c('Steps', {
    staticStyle: {
      "width": "300px",
      "margin": "0 auto"
    },
    attrs: {
      "current": _vm.step
    }
  }, _vm._l(6, function (_, index) {
    return _c('Step', {
      key: index,
      staticStyle: {
        "cursor": "pointer"
      },
      nativeOn: {
        "click": function click($event) {
          _vm.step = index;
        }
      }
    });
  }), 1), _c('Button', {
    staticStyle: {
      "position": "absolute",
      "bottom": "15px",
      "right": "15px"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.step === 6 ? _vm.$emit('close-onboarding-modal') : _vm.next();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.step === 5 ? "Done" : "Next step") + " ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
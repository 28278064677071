<template>
  <Alert v-if="!isLoading"
         show-icon
         type="warning"
  >
    <!-- <Icon type="ios-warning"
          
    /> -->
    <i
      class="fas fa-exclamation-triangle"
      slot="icon"
      :style="{
        color: 'rgb(255, 189, 65)'
      }"
    />
    <template slot="desc"
              style="margin-left:15px"
    >
      {{ retrieveText }}
    </template>
  </Alert>
</template>

<script>
import { mapGetters } from "vuex"
import { WIDGET_ALERT_NAMES } from "./constants"

export default {
  name: "DescriptionAert",
  props: {
    isLoadingProp: {
      note: "If the data is loading",
      default: false,
      type: Boolean,
    },
  },
  watch: {
    isLoadingProp: {
      imediate: true,
      handler: function (newVal, _) {
        this.isLoading = newVal
      },
    },
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      getSeletedCardKey: "cookie/getSeletedCardKey",
    }),

    retrieveText: function () {
      const { getSeletedCardKey } = this

      console.log(' WIDGET_ALERT_NAMES[getSeletedCardKey]',  WIDGET_ALERT_NAMES[getSeletedCardKey])
      return WIDGET_ALERT_NAMES[getSeletedCardKey]
    },
  },

  methods: {},
}
</script>

<style>
</style>
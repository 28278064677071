import i18n from "@/i18n/index"

export const secondsToReadable = (lifetime) => {
  const nbYears = Math.floor(lifetime / 31536000)
  const nbMonths = Math.floor(lifetime / 2628000)

  if (lifetime <= 0) return lifetime
  if (nbYears >= 2) return `${nbYears} years`
  if (nbMonths >= 2) return `${nbMonths} months`
  if (nbMonths >= 1) return "1 month"

  return `${lifetime} seconds`
}

// Quick and dirty: should be formatted server side
export const formatDateTimezone = (dateTime) => dateTime.slice(0, 10) // 19.replace('T', ' ')

export const getCookieAgeInMonthOrDays = (seconds) => {
  if (!parseInt(seconds) || parseInt(seconds) < 0) return 'variable / session'
  let days = Math.round(parseInt(seconds) / 86400)
  if (typeof days !== 'number') return 'variable / session'
  if (days > -31 && days < 31) return days + ' ' + i18n.t('global.time.days')
  const month = Math.round(days / 30.5)
  return month + ' ' + i18n.t('global.time.months')
}

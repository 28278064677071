<template>
  <Card
    v-if="!loading"
    :style="{
      cursor: 'pointer',
      height: '100%',
      position: 'relative',
      background: isSelected ? '#D2DDD9' : '#fff',
    }"
  >
    <Row
      type="flex"
      v-if="!nextWidget"
      style="align-items: center; positon: relative; padding: 10px"
    >
      <span
        v-if="showWarning && computedResult.observed "
        style="position: absolute; left: 30px; top: 20px; font-size: 24px"
      >
        <i
          class="fas fa-exclamation-triangle"
          :style="{
            color: showWarning == 'error' ? '#D55D78' : 'rgb(255, 189, 65)',
          }"
        />
      </span>
      <span
        :style="{
          margin: 'auto',
          fontSize: '80px',
          fontWeight: 200,
          color: '#1CA08D',
        }"
      >
        {{ computedResult.observed }}
      </span>
    </Row>
    <Row v-else
         style="justify-content: center"
    >
      <span
        v-if="shouldDisplayDot"
        style="font-size: 24px; display: flex; align-items: center"
      >
        <div
          style="
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background: #000;
          "
        />
      </span>
      <span
        :style="{
          margin: '0 25px',
          fontSize: '36px',
          fontWeight: 200,
          color: !isSelected ? '#1CA08D' : '#333',
        }"
      >{{ computedResult.draft }}</span>
    </Row>
    <Row v-if="!nextWidget">
      <span :style="{ width: '100%', display: 'block', textAlign: 'center' }">{{
        title
      }}</span>
    </Row>
  </Card>
  <Row
    v-else
    :gutter="24"
    :style="{
      height: nextWidget ? '50px' : '180px',
      position: 'relative',
      width: '100%',
    }"
  >
    <Spin v-if="title !== 'Vendors detected'"
          fix
    >
      <Icon type="ios-loading"
            size="26"
            class="demo-spin-icon-load"
      />
      <div>{{ $t("global.loading") }}</div>
    </Spin>
  </Row>
</template>

<script>
import { mapGetters } from "vuex"
import { eventBus } from "@/main"

export default {
  name: "CookiePolicyWidget",
  props: {
    showWarning: {
      type: [Boolean, String],
      note: "If the widget should display a warning icon",
      default: false,
    },
    isLoading: {
      type: Boolean,
      note: "if the endpoint call is loaded",
      default: false,
    },
    title: {
      type: String,
      note: "widget title",
      default: "",
    },
    componentKey: {
      type: String,
      note: "widget key",
      default: "1",
    },
   
    nextWidget: {
      type: Boolean,
      note: "If this the next widget",
      default: false,
    },
  },
  watch: {
   
    isLoading: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.loading = val
      },
    },
  },

  data() {
    return {
      bus: eventBus,
      loading: this.isLoading,
    }
  },

  mounted() {},

  methods: {},
  computed: {
    ...mapGetters({
      getObservedCookiesPolicyListByState:
        "cookie/getObservedCookiesPolicyListByState",
      getDeclareCookiesPolicyListByState:
        "cookie/getDeclareCookiesPolicyListByState",
      getDraftCookiesPolicyListByState:
        "cookie/getDraftCookiesPolicyListByState",
      getSeletedCardKey: "cookie/getSeletedCardKey",
    }),

    computedResult() {
      return {
        draft: this.getDraftCookiesPolicyListByState[this.componentKey].length,
        observed:
          this.getObservedCookiesPolicyListByState[this.componentKey].length,
      }
    },
    isSelected() {
      return this.componentKey === this.getSeletedCardKey
    },
    shouldDisplayDot() {
      return this.computedResult.draft !== this.computedResult.observed
    },
  },
}
</script>
<style lang="scss">
 .mask {
  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    background: #d2ddd9;
    width: 100%;
    height: 100%;
  }
}
</style>
<template>
  <Drawer
    v-model="isDrawerOpen"
    @on-close="$emit('on-close')"
    width="700"
  >
    <CookiePolicyDrawerContentVue :tracker-props="trackerProps"
                                  v-if="isDrawerOpen"
                                  :is-open="isOpen"
                                  :is-checking-purposes="false"
                                  @close-cookie-drawer="closeDrawer"
    />
  </Drawer>
</template>

<script>
import { eventBus } from '@/main'
import { mapGetters, mapActions } from "vuex"

import CookiePolicyDrawerContentVue from './CookiePolicyDrawerContent.vue'

export default {
  name: "CookiePolicyDrawer",
  components: {CookiePolicyDrawerContentVue},
  props: {
    isOpen: {
      type: Boolean,
      note: 'is the vendor detail panel open',
      default: true
    },
    trackerProps: {
      type: [Object],
      note: 'tracker object',
      default: null
    }
  },
  ...mapActions({
    // @TODO: add get endpoints details here
  }),

  watch: {
    isLoadingProp: {
      imediate: true,
      handler: function (newVal, _) {
        this.isLoading = newVal
      },
    },
    isOpen: {
      immediate: true,
      handler: function (val, _) {
        this.isDrawerOpen = val
      }
    }
  },

  data() {
    return {
      isLoading: false,
      bus: eventBus,
      isDrawerOpen: this.isOpen,
      vendor: null
    }
  },

  computed: {
    ...mapGetters({
    }),
  },

  methods: {
    closeDrawer () {
      // const th = this
      // debugger
    }
  },
}
</script>

<style>
</style>
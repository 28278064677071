var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Drawer', {
    attrs: {
      "width": "700"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.$emit('on-close');
      }
    },
    model: {
      value: _vm.isDrawerOpen,
      callback: function callback($$v) {
        _vm.isDrawerOpen = $$v;
      },
      expression: "isDrawerOpen"
    }
  }, [_vm.isDrawerOpen ? _c('CookiePolicyDrawerContentVue', {
    attrs: {
      "tracker-props": _vm.trackerProps,
      "is-open": _vm.isOpen,
      "is-checking-purposes": false
    },
    on: {
      "close-cookie-drawer": _vm.closeDrawer
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('Card', {
    style: {
      cursor: 'pointer',
      height: '100%',
      position: 'relative',
      background: _vm.isSelected ? '#D2DDD9' : '#fff'
    }
  }, [!_vm.nextWidget ? _c('Row', {
    staticStyle: {
      "align-items": "center",
      "positon": "relative",
      "padding": "10px"
    },
    attrs: {
      "type": "flex"
    }
  }, [_vm.showWarning && _vm.computedResult.observed ? _c('span', {
    staticStyle: {
      "position": "absolute",
      "left": "30px",
      "top": "20px",
      "font-size": "24px"
    }
  }, [_c('i', {
    staticClass: "fas fa-exclamation-triangle",
    style: {
      color: _vm.showWarning == 'error' ? '#D55D78' : 'rgb(255, 189, 65)'
    }
  })]) : _vm._e(), _c('span', {
    style: {
      margin: 'auto',
      fontSize: '80px',
      fontWeight: 200,
      color: '#1CA08D'
    }
  }, [_vm._v(" " + _vm._s(_vm.computedResult.observed) + " ")])]) : _c('Row', {
    staticStyle: {
      "justify-content": "center"
    }
  }, [_vm.shouldDisplayDot ? _c('span', {
    staticStyle: {
      "font-size": "24px",
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "10px",
      "height": "10px",
      "border-radius": "10px",
      "background": "#000"
    }
  })]) : _vm._e(), _c('span', {
    style: {
      margin: '0 25px',
      fontSize: '36px',
      fontWeight: 200,
      color: !_vm.isSelected ? '#1CA08D' : '#333'
    }
  }, [_vm._v(_vm._s(_vm.computedResult.draft))])]), !_vm.nextWidget ? _c('Row', [_c('span', {
    style: {
      width: '100%',
      display: 'block',
      textAlign: 'center'
    }
  }, [_vm._v(_vm._s(_vm.title))])]) : _vm._e()], 1) : _c('Row', {
    style: {
      height: _vm.nextWidget ? '50px' : '180px',
      position: 'relative',
      width: '100%'
    },
    attrs: {
      "gutter": 24
    }
  }, [_vm.title !== 'Vendors detected' ? _c('Spin', {
    attrs: {
      "fix": ""
    }
  }, [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "26"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.$t("global.loading")))])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contentWrapper"
  }, [_vm.discloseIntcf ? _c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" This vendor has declared purposes and features through IAB TCF v2 under vendor ID " + _vm._s(_vm.trackerProps.vendor.tcfv2_id) + ". "), _c('br'), _vm._v(" Only their declared purposes are available for selection. ")]) : _vm._e(), _c('Row', [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [!_vm.discloseIntcf && !_vm.selectedPurposes.includes(1) ? _c('p', [_vm._v(" Qualify purposes of this tracker to add it to your Cookie Policy ")]) : _vm._e()])], 1), _c('Row', {
    attrs: {
      "type": "flex",
      "gutter": 12
    }
  }, [_c('Col', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, _vm._l(_vm.purposes, function (item) {
    return _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedPurposes,
        expression: "selectedPurposes"
      }],
      key: 'purpose' + item.id + 1,
      staticStyle: {
        "margin-top": "10px"
      },
      attrs: {
        "type": "checkbox",
        "id": item.id + 1,
        "disabled": _vm.isTcfAndCurrentPurposeIsNotDeclared(item.id)
      },
      domProps: {
        "value": item.id,
        "checked": Array.isArray(_vm.selectedPurposes) ? _vm._i(_vm.selectedPurposes, item.id) > -1 : _vm.selectedPurposes
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.selectedPurposes,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = item.id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.selectedPurposes = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.selectedPurposes = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.selectedPurposes = $$c;
          }
        }
      }
    });
  }), 0), _c('Col', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_c('ul', _vm._l(_vm.purposes, function (val, key) {
    return _c('li', {
      key: key + 1,
      style: {
        marginTop: '12px',
        paddingLeft: '12px',
        color: _vm.isTcfAndCurrentPurposeIsNotDeclared(val.id) ? 'lightgray' : 'inherit'
      }
    }, [_vm._v(" " + _vm._s("".concat(key + 1, " - ").concat(val.name)) + " ")]);
  }), 0)])], 1), _c('Row', [_c('Col', {
    staticStyle: {
      "margin-top": "48px",
      "width": "100%"
    }
  }, [_c('Button', {
    staticStyle: {
      "width": "150px",
      "display": "block",
      "text-align": "center",
      "margin": "0 auto"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('qualify-tracker', {
          tracker: _vm.selectedTracker,
          purposes: _vm.selectedPurposes
        });
      }
    }
  }, [_vm._v(" Save and close ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FilterTable', {
    ref: "cookiePolicyTable",
    attrs: {
      "columns": _vm.cookieCol,
      "fetch-items": _vm.fetchItems,
      "trigger": _vm.triggerTableRefresh,
      "show-columns-selector": false,
      "allow-download": false,
      "limit": _vm.pagination.limit,
      "count": true,
      "is-cookie-policy-filters": true,
      "show-title": false,
      "container": true,
      "is-table-loading": _vm.isLoading,
      "pagination-ref": 'cookie-policy'
    }
  }), _c('CookiePolicyDrawer', {
    attrs: {
      "is-open": _vm.isOpenDrawer,
      "tracker-props": _vm.selectedTracker
    },
    on: {
      "on-close": function onClose($event) {
        _vm.isOpenDrawer = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <Row :gutter="1">
    <Col v-for="(val, key) of topWidgets"
         :key="key"
         span="6"
    >
      <div
        style="display: flex; flex-direction: column"
        @click="saveSelectedKey(val.key)"
      >
        <CookiePolicyWidget
          :show-warning="val.showWarning"
          :component-key="val.key"
          :title="val.name"
        />

        <p
          v-if="val.key === constants.WIDGET_KEYS.total"
          style="
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
              "
        >
          <strong> {{ constants.TEXTS.PROJECTED_COOKIE_POLICY }} </strong>
        </p>

        <CookiePolicyWidget
          v-if="val.key !== constants.WIDGET_KEYS.total"
          :next-widget="true"
          :show-warning="true"
          :component-key="val.key"
        />
      </div>
    </Col>
  </Row>
</template>

<script>
import {  mapMutations } from "vuex"
import CookiePolicyWidget from "./CookiePolicyWidget.vue"
import { WIDGET_KEYS, TEXTS, COOKIE_POLICY_TOP_WIDGET,  } from "./constants.js"

export default {
  components: { CookiePolicyWidget },
  name: "CookieTopWidget",

  data: function(){
    return {
      topWidgets: COOKIE_POLICY_TOP_WIDGET,
      constants: {
        TEXTS,
        WIDGET_KEYS,
      }
    }  
  },
  methods: {
    ...mapMutations({
      setSelectedKey: "cookie/SET_SELECTED_CARD_KEY",
    }),
    saveSelectedKey: function (key) {
      this.setSelectedKey(key)
    },
  }
}
</script>

<style>

</style>
<template>
  <Row type="flex"
       style="text-align: center"
       class="save-export-modal"
  >
    <Col style="margin-top: 24px"
         span="24"
    >
      <span>Here are the changes you have made to your cookie policy</span>
    </Col>

    <Col style="margin-top: 24px"
         span="24"
         class="box"
    >
      {{ getExportModalData.qualified }} Tracker Qualified
    </Col>
    <Col style="margin-top: 24px"
         span="24"
         class="box"
    >
      {{ getExportModalData.exempted }} Tracker Exempted
    </Col>
    <Col style="margin-top: 24px"
         span="24"
    >
      <span>This new list has</span>
    </Col>
    <Col style="margin-top: 24px"
         class="box"
         span="24"
    >
      {{ getExportModalData.totalQualified }} Qualified Trackers
    </Col>
    <Col style="margin-top: 24px"
         span="24"
    >
      <span>Use one of the following to build your cookie policy.</span>
    </Col>

    <Col style="margin-top: 24px"
         span="24"
    >
      <Button
        type="primary"
        style="display: block; width: 150px; margin: 0 auto"
        @click="$emit('trigger-download')"
      >
        Ok, I understand
      </Button>
    </Col>
  </Row>
</template>
<script>
import { mapGetters } from "vuex"
import { WIDGET_KEYS } from "../constants.js"

export default {
  name: "FinishExportModal",
  // watch: {
  //   // qs: {
  //   //   handler: function (oldValue, newValue) {
  //   //     newValue && this.getExportModalData()
  //   //   },
  //   // },
  // },
  computed: {
    ...mapGetters({
      qs: "scope/qs",
      draftVendorList: "vendor/draftVendorList",
      baseVendorListState: "vendor/baseVendorListState",
      getDraftCookiesPolicyListByState:
        "cookie/getDraftCookiesPolicyListByState",
      getObservedCookiesPolicyListByState:
        "cookie/getObservedCookiesPolicyListByState",
      getDeclareCookiesPolicyListByState:
        "cookie/getDeclareCookiesPolicyListByState",
    }),

    getExportModalData() { 
      const modalData = {
        [WIDGET_KEYS.exempted]: this.calculateDifference(WIDGET_KEYS.exempted),
        [WIDGET_KEYS.qualified]: this.calculateDifference(
          WIDGET_KEYS.qualified
        ),
        totalQualified: this.getDeclareCookiesPolicyListByState[WIDGET_KEYS.qualified].length
      }

      // diffenent btw declared and draft array
      console.log('modalData', modalData)

      return modalData
    },
  },
  methods: {
    calculateDifference(key) {

      return (

        this.getDraftCookiesPolicyListByState[WIDGET_KEYS[key]].length - 
        this.getObservedCookiesPolicyListByState[WIDGET_KEYS[key]].length
       
      )
    },
  },
}
</script>
<style lang="scss">
.save-export-modal {
  .box {
    max-width: 190px;
    margin: 10px auto;
    padding: 12px;
    background: #ebebeb;
    font-weight: 700;
  }
}
</style>

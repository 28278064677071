var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isLoading ? _c('Alert', {
    attrs: {
      "show-icon": "",
      "type": "warning"
    }
  }, [_c('i', {
    staticClass: "fas fa-exclamation-triangle",
    style: {
      color: 'rgb(255, 189, 65)'
    },
    attrs: {
      "slot": "icon"
    },
    slot: "icon"
  }), _c('template', {
    staticStyle: {
      "margin-left": "15px"
    },
    slot: "desc"
  }, [_vm._v(" " + _vm._s(_vm.retrieveText) + " ")])], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <main class="map-cookie-vendor"
        v-if="displayAddButton"
  >
    <Alert type="warning"
           show-icon
    >
      A tracker requires association with a vendor.
      Please select the corresponding vendor from our list or name it if not present in our vendor base
    </Alert>

    <!--    <p style="margin:12px 0">-->
    <!--      <strong>Please select an existing Vendor or name it as it doesn't exist in our vendor base </strong>-->
    <!--    </p>-->

    <Row type="flex"
         justify="space-around"
         class="container-1"
    >
      <Col span="24"
           v-if="!shouldConfirm"
      >
        <Row type="flex"
             style="width:110%"
        >
          <Input type="text"
                 style="max-width:175px;display:block;margin-right:12px"
                 suffix="ios-search"
                 placeholder="Vendor name"
                 v-model="inputName"
                 @input="debounceInput"
          />
          <Button v-if="!searchVendors.length && inputName.length"
                  type="primary"
                  @click="selectVendor(false, null)"
          >
            Add vendor
          </Button>
        </Row>
      </Col>
      <Col span="14"
           v-if="!shouldConfirm"
      >
        <ul v-if="searchVendors.length"
            style="width:175px;margin-left:-12px"
        >
          <li v-for="(val, key) of searchVendors"
              :key="key"
              class="list-item"
              @click="selectVendor(false, val)"
          >
            {{ ellipsis(val.name) }}
            <Icon type="md-add" />
          </li>
        </ul>
      </Col>
      <Col span="24"
           v-if="shouldConfirm"
      >
        <p style="margin-bottom:12px">
          Do you want to confirm that the Initiator Vendor is : <strong>{{ linkedVendorAlias || linkedVendor.name }}</strong>
        </p>

        <Button type="warning"
                @click="resetSearch"
        >
          Choose another vendor
        </Button>
        <Button type="primary"
                style="margin-left:15px;"
                @click="selectVendor(true)"
        >
          Confirm
        </Button>
      </Col>
    </Row>
    <Divider />
  </main>
  <div v-else>
    <i class="far fa-edit"
       style="font-size:14px;font-weight:bold;cursor:pointer;position: absolute;
    left: 120px;
    top: 32px;
    colo:#495060"
       @click="displayAddButton = true"
    />
  </div>
</template>

<script>
import * as _ from 'lodash'
import { mapActions, mapMutations } from 'vuex'
export default {
  name: 'VendorManualMapping',
  data () {
    return {
      inputName: '',
      searchVendors: [],
      alias: '',
      linkedVendor: null,
      linkedVendorAlias: null,
      shouldConfirm: false,
      displayAddButton: true
    }
  },
  props: {
    trackerProps: {
      note: 'selected tracker',
      required: true,
      type: Object
    }
  },
  watch: {
    trackerProps: {
      deep: true,
      handler: function (val, oldval) {
        if (!val?.vendor?.name) this.displayAddButton = true
        else this.displayAddButton = false
      }
    }
  },
  methods: {
    ...mapActions({
      getVendors : 'vendor/getVendors',
      getVendorTCF: 'vendor/getVendorTCFDeclaration'
    }),
    ...mapMutations ({
      mapVendorToCookie: 'cookie/MAP_VENDOR_TO_COOKIE'
    }),
    debounceInput: _.debounce(function (e) {
      this.viewedVendor = null
      const params = {
        category: true,
        country: true,
        count: 1,
        limit: 10,
        q: this.inputName.trim()
      }
      return this.getVendors(params)
        .then(r => {
          if (r.success) {
            this.searchVendors = r.data
          }
        })
    }, 500),
    resetSearch () {
      this.shouldConfirm = false
      this.linkedVendor = null
      this.inputName = ''
      this.linkedVendorAlias = ''
      this.searchVendors = []
    },
    applyTCF (TCF) {
      const purposes = TCF.purposes.map(purpose => purpose.id)
      this.linkedVendor['iabvendor_tcfv2_f_and_p'] = {
        purposes
      }
    },
    selectVendor (confirm = false, vendor = null) {
      if (!confirm) {
        if (vendor) this.linkedVendor = vendor
        else this.linkedVendorAlias = this.inputName
        this.shouldConfirm = true
        this.linkedVendor && this.getVendorTCF({id: vendor.iabv2Id})
          .then(r => {
            this.applyTCF(r.results)
            return 0
          })

      } else {
        const vendor = this.linkedVendor || {
          name: this.linkedVendorAlias,
          alias: this.linkedVendorAlias
        }
        this.mapVendorToCookie({vendor, cookie: this.trackerProps})
        this.resetSearch()
        this.$emit('linkToVendor', this.trackerProps)
        this.displayAddButton = false
      }
    },
    ellipsis (string = '') {
      if (string && string.length > 17) {
        return string.substring(0, 15) + '...'
      }
      return string
    }
  }
}
</script>
<style lang="scss">

    .map-cookie-vendor {
        .container-1 {
            padding: 0 24px;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
        }
        ul {
            width: 100%;
        }
        .list-item {
            padding:8px;
            background:rgb(235 235 235 / 37%);
            cursor:pointer;display:flex;
            align-items:center;
            justify-content:space-between;
            &:hover {
                background: #ebebeb;
            }
        }
    }
</style>

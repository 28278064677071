var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Row', {
    staticClass: "save-export-modal",
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "type": "flex"
    }
  }, [_c('Col', {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('span', [_vm._v("Here are the changes you have made to your cookie policy")])]), _c('Col', {
    staticClass: "box",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "span": "24"
    }
  }, [_vm._v(" " + _vm._s(_vm.getExportModalData.qualified) + " Tracker Qualified ")]), _c('Col', {
    staticClass: "box",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "span": "24"
    }
  }, [_vm._v(" " + _vm._s(_vm.getExportModalData.exempted) + " Tracker Exempted ")]), _c('Col', {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('span', [_vm._v("This new list has")])]), _c('Col', {
    staticClass: "box",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "span": "24"
    }
  }, [_vm._v(" " + _vm._s(_vm.getExportModalData.totalQualified) + " Qualified Trackers ")]), _c('Col', {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('span', [_vm._v("Use one of the following to build your cookie policy.")])]), _c('Col', {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('Button', {
    staticStyle: {
      "display": "block",
      "width": "150px",
      "margin": "0 auto"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('trigger-download');
      }
    }
  }, [_vm._v(" Ok, I understand ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contentWrapper"
  }, [_vm.trackerIsFromTcfVendor ? _c('Alert', {
    attrs: {
      "type": "error",
      "show-icon": ""
    }
  }, [_vm._v(" This vendor has declared purposes and features through IAB TCF v2 under vendor ID " + _vm._s(_vm.trackerProps.vendor.tcfv2_id) + ". "), _c('br'), _vm._v(" We recommend "), _c('u', [_vm._v("to not")]), _vm._v(" exempt trackers belonging to IAB TCF Vendors. ")]) : _vm._e(), _c('br'), _c('div', [_c('span', [_vm._v("Non Exempted")]), _c('i-switch', {
    staticClass: "switch-separator",
    on: {
      "on-change": _vm.toggleSwitch
    },
    model: {
      value: _vm.activeSwitch,
      callback: function callback($$v) {
        _vm.activeSwitch = $$v;
      },
      expression: "activeSwitch"
    }
  }), _c('span', {
    class: {
      active: _vm.activeSwitch
    }
  }, [_vm._v(" Exempted ")])], 1), _c('br'), _vm.activeSwitch ? _c('div', [_c('p', [_vm._v(" In order to qualify this tracker as exempted you hereby declare this tracker as belonging to one of the following category ")]), _c('br'), _c('p', [_vm._v(" If you need more information about the tracker exemption, we recommend you to go to this help center page. ")]), _c('br'), _vm._l(_vm.constants.exemptedRadioLists, function (exemptedRadioList) {
    return _c('Row', {
      key: exemptedRadioList.key,
      staticStyle: {
        "align-items": "center"
      }
    }, [_c('Col', {
      attrs: {
        "span": "2"
      }
    }, [_c('Radio', {
      attrs: {
        "value": exemptedRadioList.key
      },
      on: {
        "on-change": function onChange($event) {
          return _vm.handleRadioClick(exemptedRadioList.key);
        }
      },
      model: {
        value: _vm.exemptedRadioBinding[exemptedRadioList.key],
        callback: function callback($$v) {
          _vm.$set(_vm.exemptedRadioBinding, exemptedRadioList.key, $$v);
        },
        expression: "exemptedRadioBinding[exemptedRadioList.key]"
      }
    })], 1), _c('Col', {
      attrs: {
        "span": "22"
      }
    }, [_c('div', [_c('h6', [_vm._v(_vm._s(exemptedRadioList.title))]), _c('p', [_vm._v(" " + _vm._s(exemptedRadioList.description) + " ")])]), _c('br')])], 1);
  })], 2) : _vm._e(), _c('br'), _c('Button', {
    staticStyle: {
      "width": "150px",
      "display": "block",
      "text-align": "center",
      "margin": "0 auto"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.handleAddCookieToExemption
    }
  }, [_vm._v(" Save and close ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
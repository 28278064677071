<template>
  <div>
    <FilterTable
      :columns="cookieCol"
      :fetch-items="fetchItems"
      :trigger="triggerTableRefresh"
      :show-columns-selector="false"
      :allow-download="false"
      :limit="pagination.limit"
      :count="true"
      :is-cookie-policy-filters="true"
      :show-title="false"
      :container="true"
      :is-table-loading="isLoading"
      :pagination-ref="'cookie-policy'"
      ref="cookiePolicyTable"
    />
    <CookiePolicyDrawer :is-open="isOpenDrawer"
                        @on-close="isOpenDrawer = false"
                        :tracker-props="selectedTracker"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import tableColumn from '@/mixins/tableColumn'
import FilterTable from '@/components/ui/FilterTable'
import { eventBus } from '@/main'
import CookiePolicyDrawer from './CookiePolicyDrawer.vue'
import { getCookieAgeInMonthOrDays } from './../../../helpers/time'

export default {
  components: {FilterTable, CookiePolicyDrawer },
  props: {
    isLoadingProp: {
      type: Boolean,
      note: 'if Data is loading',
      default: true
    }
  },
  data: function () {
    return {
      triggerTableRefresh: false,
      getCookieAgeInMonthOrDays: getCookieAgeInMonthOrDays,
      bus: eventBus,
      isLoading: true,
      filters: null,
      selectedTracker: null,
      isOpenDrawer: false,
      items: [],
      cookieCol: this.columns(),
      pagination:  {
        count: 0,
        limit: 20,
        sort: '',
        page: 1,
        current_page: 1,
        direction: ''
      }
    }
  },
  mixins: [
    tableColumn
  ],
  watch: {
    isLoadingProp: {
      deep:true,
      immediate: true,
      handler: function (val, oldval) {
        this.isLoading = val
      }
    },
    getDraftCookiesPolicyListByState:{
      deep: true,
      handler: function (val, old) {
        this.handleKpiChange()
      }
    },
    getSeletedCardKey: {
      immediate: true,
      handler: function (val, old) {
        this.handleKpiChange()
      }
    }
  },
  mounted () {
    this.bus.$on('paginate-cookie-policy', (pagination) => {
      console.log('receive pagination', pagination)
      this.pagination = pagination
      this.pagination.current_page = pagination.page
    })
    this.bus.$on('trigger-cookie-policy-filter', (filters) => {
      this.filters = filters
      this.triggerTableRefresh = !this.triggerTableRefresh
    })
  },
  computed: {
    ...mapGetters({
      userHasRole: 'user/hasRole',
      showCategoryHelper: 'vendor/showCategoryHelper',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter',
      complianceColor: 'vendor/riskColor',
      getSeletedCardKey: 'cookie/getSeletedCardKey',
      getDraftCookiesPolicyListByState: 'cookie/getDraftCookiesPolicyListByState',
      scopeProperty: 'scope/selectedProperty'
    })
  },
  methods: {
    ...mapMutations({
      removeCookieFromDeclaredLists: 'cookie/REMOVE_DECLARED_COOKIE_IN_PRIVACY_LIST'
    }),
    ...mapActions({
      removeCookieFromPolicyList: 'cookie/removeCookieFromPolicyList'
    }),
    handleKpiChange () {
      const table = this.$refs['cookiePolicyTable']
      this.pagination.current_page = 1
      this.pagination.page = 1
      table && table.fetchData()
    },
    columns () {
      return [
        { title: ' ',
          sortable: false,
          width:'50px',
          render: (h, params) => {
            const isDeclared = params.row.declared_in_tl
            return !isDeclared
              ?h('i', {
                domProps: {
                  title: "Not qualified in Cookie Policy Project"
                },
                class: "fas fa-exclamation-triangle text-error"
              }, '')
              : h('i', {
                domProps: {
                  title: "Qualified in Cookie Policy Project"
                },
                class: "fas fa-check text-success"
              }, '')
          }
        },
        {
          title: 'Tracker name',
          render: (h, params) => {
            const t = this
            return h('a', {
              on: {
                click: () => {t.clickTracker(params.row)}
              }
            }, params.row.identifier) // tag, props, content.
          }
        },
        // {
        //   title: 'In Cookie Policy',
        //   render: (h, params) => {
        //     const isDeclared = params.row.declared_in_tl
        //     return isDeclared
        //       ? h('Icon', {
        //         style: "display:block;text-align:center;color:#81C327;font-weight:bold",
        //         props: {
        //           type: 'md-checkmark'
        //         }
        //       }, '')
        //       : ''
        //   }
        // },

        this.createColumn(this.$t('global.table.party'), 'key', false, row => ({
          content: row.is_first_party ? '1st party' : '3rd party'
        })),
        this.createColumn(this.$t('global.table.type'), 'type', false, null, 90),
        {
          title: this.$t('Exempted'),
          // render: (h, params) => {
          //   const render = h('Tag', {
          //     props: {
          //       color: 'blue'
          //     }
          //   }, 'Exempted')
          //   return params.row.is_exempted === 1 ? render : ''
          // }
          render: (h, params) => {
            return h('span', {
              domProps: {
                title: (params?.row?.is_exempted && params.row?.vendor?.tcfv2_id) ? ('this vendor is declared in TCF IAB v2 under ID '+ params.row?.vendor?.tcfv2_id +' - We recommend to not exempt trackers belonging to IAB TCF Vendors.')  : ''
              }
            },
            params?.row?.is_exempted ? (params.row?.vendor?.tcfv2_id ? 'Yes *' : 'Yes') : 'No')
          }
        },
        this.createColumn(this.$t('global.table.lifetime'), 'lifetime', false, row => ({
          content: this.getCookieAgeInMonthOrDays(row?.max_age_seconds)
        })),
        {
          title: 'Vendor',
          render: (h, params) => {
            const vendorID = params.row?.initiator_vendor?.uuid
            return vendorID
              ? h('a', { domProps: {
                target:'_blank',
                href: '#/vendors/' + params.row.initiator_vendor.uuid,
              }
              }, params.row?.initiator_vendor?.name)
              : h('span', {
                domProps: {
                  title: params.row?.vendor?.name || params.row.domain
                }
              }, params.row?.vendor?.name || 'N/A')
          }
        },
        {
          title: 'Vendor category',
          render: (h, params) => h('span', {}, params.row?.vendor?.category_name || 'N/A')
        },
        {
          title: 'Vendor country',
          render: (h, params) => h('span', {}, params.row?.vendor?.country_name || 'N/A')
        },
        {
          title: 'Action',
          width: "90px",
          render: (h, params) => {
            const action = this.handleActionClick
            return h('Button', {
              props: {
                icon: params.row.declared_in_tl ? 'md-remove' : 'md-add',
                type: params.row.declared_in_tl ? 'warning' : 'primary'
              },
              on: {
                click: () => action(params.row)
              }
            })
          }
        }
      ]
    },
    // @TODO: use getters instead
    vendorVigilance (vendor) {
      if (!vendor) return ''
      const riskColor = {
        A: '#81C327',
        B: '#6BAAD8',
        C: '#FFBD41',
        D: '#FF7850',
        E: '#D55D78'
      }
      const vigilance = this.vendorVigilanceToLetter(vendor.vigilance_rating * 5)

      return {
        vigilance: vigilance,
        color: riskColor[vigilance]
      }
    },
    // @TODO: replace string name by constant string name
    // As well in ./CookieTableFilters.vue
    applyFilters (data) {
      const { filters } = this
      if (!filters) return data
      let draftData = JSON.parse(JSON.stringify(data))
      if (filters.inputName) {
        let filteredVnd = []
        let filteredIdentifier = []
        filteredIdentifier = draftData.filter(elm => {
          if (!elm.identifier) return false
          const name = elm.identifier.toLowerCase()
          return name.includes(filters.inputName.toLowerCase())
        })
        filteredVnd = draftData.filter(elm => {
          if (!elm?.vendor?.name) return false
          const name = elm.vendor.name.toLowerCase()
          return name.includes(filters.inputName.toLowerCase())
        })
        const mergedResults = [...filteredVnd, ...filteredIdentifier]
        const deduplicatedResults = Object.values(
          mergedResults.reduce( (c, e) => {
            if (!c[e.hash_namehosttype]) c[e.hash_namehosttype] = e
            return c
          }, {})
        )
        draftData = deduplicatedResults
      }
      if (filters.type.length) {
        const types = filters.type.map(elm => elm.toLowerCase())
        draftData = draftData.filter(tracker => types.includes(tracker.type))
      }
      if (filters.partyFrom.length === 1) {
        if (filters.partyFrom[0] === '1st party') {
          draftData = draftData.filter(tracker => tracker.is_first_party)
        } else {
          draftData = draftData.filter(tracker => !tracker.is_first_party)
        }
      }
      if (filters.lifetime.length === 1) {
        if (filters.lifetime[0] !== 'Less or equal 13 months') {
          draftData = draftData.filter(tracker => tracker.max_age_seconds && (Math.round(tracker.max_age_seconds / 86400)) > 396)
        } else {
          draftData = draftData.filter(tracker => !tracker.max_age_seconds || (Math.round(tracker.max_age_seconds / 86400)) <= 396)
        }
      }
      if (filters.purposes.length) {
        draftData = draftData.filter(tracker => {

          let index = true
          filters.purposes.forEach(pp => {
            if (!tracker.purposes.includes(pp)) index = false
          })
          return index

        })
        // doing purposes filteringg
      }
      if (filters.isDetected.length) {
        if (filters.isDetected[0] === 'Yes') draftData = draftData.filter(tracker => tracker.detected_in_trackers)
        if (filters.isDetected[0] !== 'Yes') draftData = draftData.filter(tracker => !tracker.detected_in_trackers)
      }
      if (filters.inTCF.length) {
        const filter = filters.inTCF
        if (filter.length === 1 && filter[0] === 'Yes') draftData = draftData.filter(tracker => tracker?.vendor?.tcfv2_id)
        if (filter.length === 1 && filter[0] !== 'Yes') draftData = draftData.filter(tracker => !tracker?.vendor?.tcfv2_id)
      }
      return draftData
    },

    paginate (data) {
      const filteredData = this.applyFilters(JSON.parse(JSON.stringify(data)))
      this.pagination.count = filteredData.length
      this.pagination.limit = 20
      return filteredData.slice((this.pagination.page - 1) * this.pagination.limit, this.pagination.page * this.pagination.limit)
    },
    fetchItems () {
      const fn = new Promise((resolve, reject) => {
        resolve({
          success: true,
          data: this.paginate(this.getDraftCookiesPolicyListByState[this.getSeletedCardKey]),
          pagination: this.pagination
        })
      })
      return fn
    },
    clickTracker (tracker) {
      this.selectedTracker = tracker
      this.isOpenDrawer = true
      console.log('here')
    },
    removeTrackerFromTrackerList (tracker) {
      const payload = {
        properties: [this.scopeProperty.id],
        hash_namehosttype: tracker.hash_namehosttype

      }
      return this.removeCookieFromPolicyList(payload)
        .then(r => {
          if (r.success) {
            this.removeCookieFromDeclaredLists(tracker)
            return this.$Notice.success({
              title: 'Tracker' + tracker.identifier,
              desc: 'Has been removed from cookie policy'
            })
          }
        })
    },
    handleActionClick (tracker) {
      const declared = tracker.declared_in_tl
      if (!declared) return this.clickTracker(tracker)
      return this.removeTrackerFromTrackerList(tracker)
    }
  }
}
</script>

<template>
  <div v-if="trigger">
    <div class="tracker-content">
      <div>
        <h1>{{ selectedTracker.identifier }}</h1>
        <br>
        <h6 style="text-align:center">
          {{ selectedTracker?.vendor?.name || '' }}
        </h6>
      </div>
    </div>
    <Tabs value="PURPOSES"
          v-model="tabActive"
    >
      <TabPane label="IDENTITY"
               name="IDENTITY"
      >
        <VendorManualMapping v-if="!selectedTracker?.vendor_id"
                             :tracker-props="selectedTracker"
                             @linkToVendor="refreshCookieData"
        />
        <Identity :tracker-props="selectedTracker"
                  @qualify-tracker="handleApplyAction"
        />
      </TabPane>
      <TabPane label="PURPOSES"
               name="PURPOSES"
      >
        <PurposeList ref="cookie-purposes-list"
                     @qualify-tracker="handleApplyAction"
                     @purposes-sent="handlePurposeChange"
                     :tracker-props="selectedTracker"
        />
      </TabPane>
      <TabPane label="EXEMPTION"
               name="EXEMPTION"
      >
        <Exemption :tracker-props="selectedTracker"
                   @exemption-sent="handleApplyAction"
        />
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import PurposeList from './Tabs/PurposeList.vue'
import Exemption from "./Tabs/Exemption.vue"
import Identity from "./Tabs/Identity.vue"
import VendorManualMapping from "./Tabs/VendorManualMapping.vue"
import { eventBus } from '@/main'

export default {
  name: "CookiePolicyDrawerContent",
  components: {PurposeList, Exemption, Identity, VendorManualMapping},
  data () {
    return {
      bus: eventBus,
      selectedTracker: null,
      trigger: true,
      shouldDisableExemption: false,
      tabs: [
        {
          label: 'IDENTITY',
        },
        {
          label: 'PURPOSES',
        },
        {
          label: 'EXEMPTION',
          // disabled: this.shouldDisableExemption
        }
      ]
    }
  },
  props: {
    trackerProps: {
      note: 'the clicked tracker',
      default: null,
      type: Object
    }
  },
  watch: {
    trackerProps:{
      deep:true,
      immediate: true,
      handler: function (val, _) {
        this.selectedTracker = val
        this.tabActive = "PURPOSES"
      }
    }
  },
  computed: {
    ...mapGetters({
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter',
      draftCookieList: 'cookie/getDraftCookiesPolicyListByState',
      complianceColor: 'vendor/riskColor',
      scopeSelectedProperty: 'scope/selectedProperty'
    })
  },
  methods: {
    ...mapMutations({
      qualifyNewTracker: 'cookie/QUALIFY_NEW_COOKIE',

    }),
    ...mapActions( {
      addCookieToPolicyList: 'cookie/handleAddTrackerToCookiePolicy'
    }),
    applyTcfVendorRestrictions () {
      this.shouldDisableExemption = false
      if (this.selectedTracker?.vendor?.iabv2Id || this.selectedTracker?.vendor?.tcfv2_id) {
        // if (this.selectedTracker?.vendor?.iabvendor_tcfv2_f_and_p?.purposes.includes(1)) this.shouldDisableExemption = true
      }
    },
    refreshCookieData (cookie) {
      const lists = this.draftCookieList.total
      this.selectedTracker = lists.find(elm => elm.hash_namehosttype === cookie.hash_namehosttype)
      this.applyTcfVendorRestrictions()
    },
    handlePurposeChange (purposes) {
      // console.log(purposes.includes(1))
      // this.shouldDisableExemption = purposes.includes(1)
    },
    getCookieAgeInMonthOrDays (seconds) {
      let days = Math.round(parseInt(seconds) / 86400)
      if (typeof days !== 'number') return 'N/A'
      if (days > -31 && days < 31) return days + ' ' + this.$t('global.time.days')
      const month = Math.round(days / 30.5)
      return month + ' ' + this.$t('global.time.months')
    },

    lifetime (seconds) {
      if (seconds === null || seconds < 0) return 'No lifetime'
      if (seconds == 0 ) return '0 Days'
      return this.getCookieAgeInMonthOrDays(seconds)

    },
    vendorVigilance (vendor) {
      if (!vendor) return ''
      const riskColor = {
        A: '#81C327',
        B: '#6BAAD8',
        C: '#FFBD41',
        D: '#FF7850',
        E: '#D55D78'
      }
      const vigilance = this.vendorVigilanceToLetter(vendor?.vigilance_rating * 5)

      return {
        vigilance: vigilance,
        color: riskColor[vigilance]
      }
    },

    handleApplyAction ({tracker = this.trackerProps, purposes = this.$refs['cookie-purposes-list'].selectedPurposes, exemption = []}) {
      return this.addCookieToPolicyList({tracker, purposes, exemption})
        .then(r => {
          if (!r.success) {
            return this.$Notice.warning({
              title: 'Error qualyfing trackers...',
            })
          }
          this.$Notice.success({
            title: 'Tracker: ' + tracker.identifier,
            desc: this.trackerProps.declared_in_tl ? 'Has been updated' : 'Has been added to the cookie list'
          })

        })
        .finally(() => {
          this.$parent.handleClose()
        })

    },
    generateDataObject ({ payload = this.selectedTracker }) {
      return {
        properties: [this.scopeSelectedProperty.id],
        hash_namehosttype: payload.hash_namehosttype,
        identifier: payload.identifier,
        vendor_id: payload.vendor_id,
        domain: payload.domain,
        type: payload.type,
        max_age_seconds: payload.max_age_seconds,
        exempted: !!payload?.exemption_declarations.length,
        vendor_name: payload?.vendor?.name  || '', //@todo:  ask henry here
        purposes: payload.purposes.length ? payload.purposes : null,
        exemption_declarations: payload.exemption_declarations.length ? payload.exemption_declarations : null
      }
    }
  }
}
</script>

<style lang="scss">
.tracker-content {
  width: 100%;
  display: flex;
  margin: 5px 0;
  justify-content: center;
}

.vendor-field-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0;

  .vendor-field-title {
    width: 150px;
    font-weight: 300;
  }
  .vendor-field-value {
    // font-weight: 700;
  }
}
</style>

<template>
  <Row class="contentWrapper"
       style="width: 450px; margin: 0 auto"
  >
    <div class="vendor-field-container">
      <span class="vendor-field-title">Vendor</span>
      <span class="vendor-field-value">{{
        selectedTracker?.vendor
          ? selectedTracker.vendor.name
          : ""
      }}</span>
    </div>
    <div class="vendor-field-container">
      <span class="vendor-field-title">Vendor Category</span>
      <span class="vendor-field-value"> {{ selectedTracker?.vendor?.category_name }} </span>
    </div>
    <div class="vendor-field-container">
      <span class="vendor-field-title">Vendor Trust Index</span>
      <span class="vendor-field-value">
        <Tag
          type="dot"
          :color="
            complianceColor[
              this.vendorVigilanceToLetter(
                selectedTracker?.vendor?.vigilance_rating
              )
            ]
          "
        >{{
          this.vendorVigilanceToLetter(
            selectedTracker?.vendor?.vigilance_rating
          )
        }}</Tag>
      </span>
    </div>
    <div class="vendor-field-container">
      <span class="vendor-field-title">Domain</span>
      <span class="vendor-field-value"> {{ selectedTracker?.domain }} </span>
    </div>
    <div class="vendor-field-container">
      <span class="vendor-field-title">1st/3rd Party</span>
      <span class="vendor-field-value">
        {{ selectedTracker.is_first_party ? "1st Party" : "3rd Party" }}
      </span>
    </div>
    <div class="vendor-field-container">
      <span class="vendor-field-title">Type</span>
      <span class="vendor-field-value"> {{ selectedTracker.type }} </span>
    </div>
    <div class="vendor-field-container">
      <span class="vendor-field-title">Lifetime</span>
      <span class="vendor-field-value">
        {{ getCookieAgeInMonthOrDays(selectedTracker.max_age_seconds) }}
      </span>
    </div>
    <div class="vendor-field-container">
      <span class="vendor-field-title">Disclosed In Tcf</span>
      <span class="vendor-field-value"> <strong>{{ selectedTracker?.vendor?.tcfv2_id || selectedTracker?.vendor?.iabv2Id || 'No' }}</strong></span>
    </div>
    <Col style="margin-top: 48px;width:100%;">
      <Button type="warning"
              :disabled="!trackerProps?.vendor?.name"
              @click="$emit('qualify-tracker', {tracker: trackerProps})"
              style="width:150px; display:block;text-align:center;margin:0 auto;"
      >
        Qualify
      </Button>
    </Col>
  </Row>
</template>

<script>
import { mapGetters } from "vuex"
import { getCookieAgeInMonthOrDays } from './../../../../helpers/time'

export default {
  name: "Identity",

  props: {
    trackerProps: {
      note: "the clicked tracker",
      default: null,
      type: Object,
    },
  },

  data() {
    return {
      selectedTracker: null,
      getCookieAgeInMonthOrDays: getCookieAgeInMonthOrDays
    }
  },

  watch: {
    trackerProps: {
      deep: true,
      immediate: true,
      handler: function (val, _) {
        this.selectedTracker = val
      },
    },
  },

  computed: {
    ...mapGetters({
      vendorVigilanceToLetter: "vendor/vendorVigilanceToLetter",
      complianceColor: "vendor/riskColor",
    }),
  },

  methods: {
 
  },
}
</script>

<style lang="scss">
.vendor-field-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0;

  .vendor-field-title {
    width: 150px;
    font-weight: 300;
  }
  .vendor-field-value {
    // font-weight: 700;
  }
}
</style>
<template>
  <div class="cookie-policy">
    <Button
      type="primary"
      style="float:right;"
      size="large"
      @click="showFinishExportModal = true"
    >
      {{ constants.TEXTS.EXPORT_QUALIFIED }}
    </Button>
    <Button
      type="info"
      style="float:right;"
      size="large"
      @click="showOnboardingModal = true"
    >
      Guide
    </Button>
    <h1>Cookie Policy Management</h1>
    <br>
    <div v-if="!isLoading">
      <!-- <CookieTopWidget /> -->
      <table class="cookielistactions-table">
        <tr>
          <td rowspan="2"
              :class="(getSeletedCardKey === constants.WIDGET_KEYS.total)?'selected':''"
          >
            <DeclarationVendorWidget ref="total"
                                     :is-loading="isLoading"
                                     :data="getObservedCookiesPolicyListByState.total"
                                     title="Active trackers"
                                     title-icon="info"
                                     :font-size="(getDraftCookiesPolicyListByState.qualified.length > 0 )?'x-large':'large'"
                                     @click.native="handleClickWidget(constants.WIDGET_KEYS.total)"
                                     description="Total number of active trackers detected on your website."
            />
          </td>
          <td :class="(getSeletedCardKey === constants.WIDGET_KEYS.qualified)?'selected':''">
            <DeclarationVendorWidget ref="qualified"
                                     :is-loading="isLoading"
                                     :data="getObservedCookiesPolicyListByState.qualified"
                                     title="Qualified trackers"
                                     title-icon="success"
                                     :font-size="(getDraftCookiesPolicyListByState.qualified.length > 0 )?'large':'large'"
                                     @click.native="handleClickWidget(constants.WIDGET_KEYS.qualified)"
                                     description="Trackers already qualified."
            />
          </td>
          <td :class="(getSeletedCardKey === constants.WIDGET_KEYS.unqualified)?'selected':''">
            <DeclarationVendorWidget ref="total"
                                     :is-loading="isLoading"
                                     :data="getObservedCookiesPolicyListByState.unqualified"
                                     title="Unqualified trackers"
                                     title-icon="error"
                                     :font-size="(getDraftCookiesPolicyListByState.unqualified.length > 0 )?'large':'large'"
                                     @click.native="handleClickWidget(constants.WIDGET_KEYS.unqualified)"
                                     description="Unqualified trackers requiring declaration."
            />
          </td>
          <td :class="(getSeletedCardKey === constants.WIDGET_KEYS.exempted)?'selected':''">
            <DeclarationVendorWidget ref="total"
                                     :is-loading="isLoading"
                                     :data="getObservedCookiesPolicyListByState.exempted"
                                     title="Exempted trackers"
                                     title-icon="info"
                                     :font-size="(getDraftCookiesPolicyListByState.exempted.length > 0 )?'large':'large'"
                                     @click.native="handleClickWidget(constants.WIDGET_KEYS.exempted)"
                                     description="Detected exempted."
            />
          </td>
        </tr>

        <tr :class="(getDraftCookiesPolicyListByState.qualified.length > 0)?'':'hidden'">
          <td :class="(getSeletedCardKey === constants.WIDGET_KEYS.qualified)?'selected':''">
            <DeclarationVendorWidget :next-widget="true"
                                     :data="getDraftCookiesPolicyListByState.qualified"
                                     :base-data="getObservedCookiesPolicyListByState.qualified"
                                     :more-is-better="true"
                                     title="Qualified trackers for Cookie Policy project"
                                     font-size="large"
                                     @click.native="handleClickWidget(constants.WIDGET_KEYS.qualified)"
            />
          </td>
          <td :class="(getSeletedCardKey === constants.WIDGET_KEYS.unqualified)?'selected':''">
            <DeclarationVendorWidget :next-widget="true"
                                     :data="getDraftCookiesPolicyListByState.unqualified"
                                     :base-data="getObservedCookiesPolicyListByState.unqualified"
                                     :more-is-better="false"
                                     title="Trackers not qualified for this Cookie Policy project"
                                     font-size="large"
                                     @click.native="handleClickWidget(constants.WIDGET_KEYS.unqualified)"
            />
          </td>
          <td :class="(getSeletedCardKey === constants.WIDGET_KEYS.exempted)?'selected':''">
            <DeclarationVendorWidget :next-widget="true"
                                     :data="getDraftCookiesPolicyListByState.exempted"
                                     :base-data="getObservedCookiesPolicyListByState.exempted"
                                     :more-is-better="true"
                                     title="Exempted trackers in this Cookie Policy project"
                                     font-size="large"
                                     @click.native="handleClickWidget(constants.WIDGET_KEYS.exempted)"
            />
          </td>
        </tr>

        <tr>
          <td colspan="4">
            <Card v-if="getSeletedCardKey === constants.WIDGET_KEYS.total"
                  :bordered="false"
                  :dis-hover="true"
            >
              <br>
              <h1>Total number of active trackers detected on your website</h1>
              <br>
              <Row>
                <Col :md="14"
                     :sm="24"
                >
                  <div>
                    <p>Displays all trackers that were detected on your website for the selected period.</p>
                  </div>
                </Col>
                <Col :md="10"
                     :sm="24"
                >
                  <div>
                    <Button
                      type="primary"
                      size="large"
                      @click="showDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal = true"
                    >
                      {{ constants.TEXTS.AUTO_QUALIFY_TCF }}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>


            <Card v-if="getSeletedCardKey === constants.WIDGET_KEYS.qualified"
                  :bordered="false"
                  :dis-hover="true"
            >
              <br>
              <h1>Trackers already qualified</h1>
              <br>
              <Row>
                <Col :md="16"
                     :sm="24"
                >
                  <div>
                    <p>Trackers already prepared and qualified on your Cookie Policy Project</p>
                  </div>
                </Col>
                <Col :md="8"
                     :sm="24"
                >
                  <div>
                    <Button
                      type="primary"
                      size="large"
                      @click="showDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal = true"
                    >
                      {{ constants.TEXTS.AUTO_QUALIFY_TCF }}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>


            <Card v-if="getSeletedCardKey === constants.WIDGET_KEYS.unqualified"
                  :bordered="false"
                  :dis-hover="true"
            >
              <br>
              <h1>Unqualified trackers requiring declaration</h1>
              <br>
              <Row>
                <Col :md="16"
                     :sm="24"
                >
                  <div>
                    <p>
                      In order to generate the cookie policy you need to either associate purposes or exempt these trackers
                    </p>
                  </div>
                </Col>
                <Col :md="8"
                     :sm="24"
                >
                  <div>
                    <Button
                      type="primary"
                      size="large"
                      @click="showDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal = true"
                    >
                      {{ constants.TEXTS.AUTO_QUALIFY_TCF }}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>

            <Card v-if="getSeletedCardKey === constants.WIDGET_KEYS.exempted"
                  :bordered="false"
                  :dis-hover="true"
            >
              <br>
              <h1>Exempted trackers</h1>
              <br>
              <Row>
                <Col :md="16"
                     :sm="24"
                >
                  <div>
                    <p>
                      Trackers described here can be of two sources, Agnostik exempted trackers and publisher exempted trackers.
                    </p>
                    <p>
                      You cannot un-exempt an Agnostik exempted trackers
                    </p>
                  </div>
                </Col>
                <Col :md="8"
                     :sm="24"
                     v-if="userHasRole('super-admin') "
                >
                  <div>
                    <Button
                      type="warning"
                      size="large"
                      @click="(showDeclareExemptedtrackersModal = true)"
                    >
                      Apply property trackers exemptions to incoming data
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>
          </td>
        </tr>
      </table>

      <br>
      <Modal
        v-model="showDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal"
        width="640px"
        :title="constants.TEXTS.AUTO_QUALIFY_TCF"
        @on-ok="handleDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal"
        @on-cancel="showDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal = false"
      >
        <p>This feature will qualify trackers belonging to identified TCF vendors and add them to the Cookie Policy project</p>
        <p>Each tracker Purposes will be set to their default vendor TCF settings.</p>
        <br>
        <Alert type="warning"
               show-icon
        >
          Do note that exempted trackers will not be added
        </Alert>
      </Modal>

      <Modal
        v-model="showDeclareExemptedtrackersModal"
        width="640px"
        title="Apply property trackers exemptions"
        @on-ok="handleDeclareExemptedtrackersModal"
        @on-cancel="showDeclareExemptedtrackersModal = false"
      >
        <div v-if="appliedExemptedTrackers.length == 0">
          <p>By proceeding, the following trackers will be exempted for this property.</p>
          <br>
          <table style="width:100%"
                 class="ivu-table ivu-table-default"
          >
            <thead>
              <th>identifier</th><th>domain</th><th>type</th>
            </thead>
            <tr v-for="tracker in getDeclareCookiesPolicyListByState.exempted"
                :key="tracker.hash_namehosttype"
            >
              <td>{{ tracker.identifier }}</td><td>{{ tracker.domain }}</td><td>{{ tracker.type }}</td>
            </tr>
          </table>
        </div>
        <div v-else>
          <Alert type="warning"
                 show-icon
          >
            The following trackers have been exempted and any new data for this property will apply these exemptions.
          </Alert>

          <br>
          <table style="width:100%"
                 class="ivu-table ivu-table-default"
          >
            <thead>
              <th>identifier</th><th>domain</th><th>type</th>
            </thead>
            <tr v-for="tracker in appliedExemptedTrackers"
                :key="tracker.hash_namehosttype"
            >
              <td>{{ tracker.identifier }}</td><td>{{ tracker.domain }}</td><td>{{ tracker.type }}</td>
            </tr>
          </table>
        </div>
      </Modal>

      <!-- <DescriptionAlertVue :is-loading-prop="isLoading" /> -->
      <CookiePolicyTable :is-loading-prop="isLoading" />

      <Modal
        v-model="showOnboardingModal"
        class="vlm-help-modal"
        width="640px"
        title="Cookie Policy Guide"
      >
        <OnboardingModal @close-onboarding-modal="showOnboardingModal = false" />
      </Modal>
    </div>
    <div v-else>
      <Spin>
        <Icon type="ios-loading"
              size="26"
              class="demo-spin-icon-load"
        />
        <div>{{ $t("global.loading") }}</div>
      </Spin>
    </div>

    <Modal
      draggable
      v-model="showFinishExportModal"
      class="vlm-help-modal"
      title="Finish Export"
      width="640px"
    >
      <FinishExportModal @trigger-download="exportQualifiedTrackers()" />
    </Modal>
  </div>
</template>

<script>
import {TCFV2} from '@/config'
import { mapGetters, mapActions, mapMutations } from "vuex"

import CookiePolicyTable from "./CookiePolicyTable.vue"
import CookieTopWidget from "./CookieTopWidget.vue"
import DescriptionAlertVue from "./DescriptionAlert.vue"
import OnboardingModal from "./Modals/OnboardingModal.vue"
import FinishExportModal from "./Modals/FinishExportModal.vue"
import DeclarationVendorWidget from './../../Vendors/VendorList/DeclarationVendorWidget.vue'

import { WIDGET_KEYS, COOKIE_POLICY_TOP_WIDGET, TEXTS } from "./constants.js"
import { downloadFile } from "@/helpers/fileDownloads.js"
import { getCookieAgeInMonthOrDays } from "@/helpers/time.js"
import { EXEMPTED_RADIO_LIST } from "./constants"
export default {
  name: "CookiePolicy",
  components: {
    DeclarationVendorWidget,
    CookiePolicyTable,
    // DescriptionAlertVue,
    // CookieTopWidget,
    OnboardingModal,
    FinishExportModal,
  },
  data: function () {
    return {
      isLoading: false,
      showDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal: false,
      showDeclareExemptedtrackersModal:false,
      showOnboardingModal: false,
      showFinishExportModal: false,
      detectedTrackers: [],
      declaredTrackers: [],
      appliedExemptedTrackers: [],
      constants: {
        TEXTS,
        WIDGET_KEYS
      },
      function: {
        downloadFile,
      },
      default_tcfv2_f_and_p: TCFV2.FEATURES_AND_PURPOSES,
    }
  },
  mounted() {
    this.qs && this.fetchDetectedTrackers()
  },
  watch: {
    qs: {
      handler: function (oldValue, newValue) {
        this.fetchDetectedTrackers()
      },
    },
  },
  computed: {
    ...mapGetters({
      userHasRole: 'user/hasRole',
      property: "scope/selectedProperty",
      period: "scope/selectedDaterange",
      qs: "scope/qs",
      getSeletedCardKey: "cookie/getSeletedCardKey",
      getDraftCookiesPolicyListByState:
        "cookie/getDraftCookiesPolicyListByState",
      getDeclareCookiesPolicyListByState:
        "cookie/getDeclareCookiesPolicyListByState",
      getObservedCookiesPolicyListByState: 'cookie/getObservedCookiesPolicyListByState'
    }),
  },
  methods: {
    ...mapMutations({
      setObservedCookiePolicy: "cookie/SET_OBSERVED_COOKIE_POLICY_LIST",
      setDeclaredCookiePolicy: "cookie/SET_DECLARED_COOKIE_POLICY_LIST",
      setSelectedKey: "cookie/SET_SELECTED_CARD_KEY",

    }),
    ...mapActions({
      getCookies: "cookie/getCookies",
      handleAddTrackerToCookiePolicy: "cookie/handleAddTrackerToCookiePolicy",
      handleAddDeclaredExemptedToPropertyExemptedTracker: "cookie/handleAddDeclaredExemptedToPropertyExemptedTracker",
      getObservedTrackerList:
        "cookie/getObservedDeclaredPrivacyPolicyTrackerList",
      getDeclaredTrackerList: "cookie/getDeclaredPrivacyPolicyTrackerList",
    }),
    handleClickWidget (elm) {
      this.setSelectedKey(elm)
    },
    async handleDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal() {
      const { getDraftCookiesPolicyListByState } = this
      const unqualified = getDraftCookiesPolicyListByState.unqualified
      const unqualified_TCF = unqualified.filter(
        (elm) =>
          elm?.vendor?.tcfv2_id && !elm?.is_exempted && !elm.declared_in_tl
      )
      for await (const tracker of unqualified_TCF) {
        const purposes = tracker.vendor.iabvendor_tcfv2_f_and_p.purposes
        await this.handleAddTrackerToCookiePolicy({ tracker, purposes })
      }
      this.$Notice.success({
        title: "Tracker qualification",
        desc: unqualified_TCF.length
          ? `${unqualified_TCF.length} non exempted trackers were qualified`
          : "All trackers dropped by TCF vendor are already in cookie policy",
      })
    },
    async handleDeclareExemptedtrackersModal() {
      if (this.appliedExemptedTrackers.length > 0 ) {
        this.appliedExemptedTrackers.length = []
      }else{
        this.handleAddDeclaredExemptedToPropertyExemptedTracker( { properties : [this.property.id] } )
          .then((results) => {
            this.showDeclareExemptedtrackersModal = true
            if(results.success){
              this.appliedExemptedTrackers = results.data
            }
          })
      }

    },

    mergeDeclareAndObserved() {
      const { declaredTrackers, detectedTrackers } = this
      declaredTrackers.forEach((tracker) => {
        const detectedOne =
          detectedTrackers.find(
            (elm) => elm.hash_namehosttype === tracker.hash_namehosttype
          ) || null
        if (detectedOne) {
          detectedOne.declared_in_tl = 1
          detectedOne.purposes = tracker.purposes || []
          detectedOne.exemption_declarations =
            tracker.exemption_declarations || []
          detectedOne.is_exempted = tracker?.exempted || 0
          detectedOne.vendor = tracker.vendor || null
          if (!tracker.vendor_id) {
            detectedOne.vendor_name = tracker.vendor_name
            detectedOne.vendor = {
              name: tracker.vendor_name,
            }
          }
        }
      })
      this.mapPurposes()
    },
    mapPurposes() {
      const { detectedTrackers, declaredTrackers } = this
      let trackers = detectedTrackers.map((tracker) => {
        const purposes =
          tracker?.purposes ||
          tracker?.vendor?.iabvendor_tcfv2_f_and_p?.purposes ||
          []
        tracker.purposes = purposes
        return tracker
      })

      this.setObservedCookiePolicy(trackers)
      this.setDeclaredCookiePolicy(declaredTrackers)
    },
    fetchDetectedTrackers: async function () {
      this.isLoading = true

      const params = {
        properties: [this.property.id],
        period: this.period,
      }

      try {
        const [observedData, delacredData] = await Promise.all([
          this.getObservedTrackerList(params),
          this.getDeclaredTrackerList(params),
        ])

        if (!observedData.success || !delacredData.success) {
          this.$Message.warning("Error fetching request")
        }

        this.detectedTrackers = observedData.data
        this.declaredTrackers = delacredData.data

        this.showOnboardingModal = !this.declaredTrackers.length

        this.mergeDeclareAndObserved()
      } catch (error) {
        this.$Message.warning("Error fetching request")
      } finally {
        this.isLoading = false
      }
    },

    getExportableCookiePolicyData(cookiePolicies = []) {
      // fetch('https://vendor-list.consensu.org/v2/purposes-fr.json')
      //   .then((response) => response.json())
      //   .then((data) => console.log(data))


      return cookiePolicies.map((cookiePolicy) => ({
        "Vendor Name": cookiePolicy?.vendor?.name || "N/A",
        "vendor tcf id": cookiePolicy?.vendor?.tcfv2_id || "Not in TCF",
        "Tracker Name": cookiePolicy?.identifier || "N/A",
        "Tracker domain": cookiePolicy?.domain || "N/A",
        "tracker exemption":
          cookiePolicy.is_exempted || cookiePolicy.exempted
            ? "Exempted"
            : "Not exempted",
        "tracker exemption title":
          EXEMPTED_RADIO_LIST[cookiePolicy.exemption_declarations[0]]?.key ||
          "",
        "tracker exemption description":
          EXEMPTED_RADIO_LIST[cookiePolicy.exemption_declarations[0]]
            ?.description || "",
        "Life Time": getCookieAgeInMonthOrDays(cookiePolicy.max_age_seconds),
        "1st Party/ 3rd Party": cookiePolicy?.is_first_party
          ? "1st Party"
          : "3rd Party",
        "Tracker Type": cookiePolicy?.type || "N/A",
        "Tracker Purposes Ids":
          cookiePolicy?.purposes && cookiePolicy?.purposes.length
            ? cookiePolicy?.purposes
            : "",
        "Tracker Purposes Names":
          cookiePolicy?.purposes && cookiePolicy?.purposes.length
            ? cookiePolicy?.purposes.map(elm => this.default_tcfv2_f_and_p.purposes[elm].name )
            : "",
        "Hash": cookiePolicy?.hash_namehosttype || " ",
      }))
    },

    getExportableCookiePolicyDataJSON(reformedCookiePolicy = []) {
      const hashMap = {}

      const restructuredMap = reformedCookiePolicy.reduce((total, curr) => {
        if (!hashMap[curr["Vendor Name"]]) {
          total[curr["Vendor Name"]] = [curr]
        } else {
          total[curr["Vendor Name"]].push(curr)
        }

        hashMap[curr["Vendor Name"]] = true
        return total
      }, {})

      return Object.keys(restructuredMap).map((key) => {
        return {
          "Vendor Name": key,
          trackers: restructuredMap[key],
        }
      })
    },

    exportQualifiedTrackers: function (key) {
      const cookiePolicies =
        this.getDeclareCookiesPolicyListByState[
          COOKIE_POLICY_TOP_WIDGET[1].key
        ]

      const mapCookiePolicyList =
        this.getExportableCookiePolicyData(cookiePolicies)

      downloadFile({
        list: this.getExportableCookiePolicyDataJSON(mapCookiePolicyList),
        title: "qualified_cookie_policy",
        extension: "JSON",
      })

      const csvHeaders = [
        "Vendor Name",
        "Tracker Name",
        "Tracker domain",
        "tracker exemption",
        "tracker exemption title",
        "tracker exemption description",
        "Life Time",
        "1st Party/ 3rd Party",
        "Tracker Type",
        "Tracker Purposes Ids",
        "Tracker Purposes Names",
        "Hash",
      ]

      downloadFile({
        list: mapCookiePolicyList,
        title: "qualified_cookie_policy",
        extension: "CSV",
        headers: csvHeaders,
      })
      this.showFinishExportModal = false
    },
  },
}
</script>
<style lang="scss">
.cookie-policy {
  .ivu-card {
    margin-top: 0px !important;
  }
}

.vlm-help-modal {
  .ivu-modal-header-inner {
    text-align: center;
  }
  .ivu-modal-footer {
    display: none;
  }
}
button ~ button{
  margin-right: 16px;
}

table.cookielistactions-table {
  border-spacing: 12px 0px;
  border-collapse: separate;
  transition: all 0.25s;
  width: calc(100% + 24px);
  margin-left:-12px;
  height: 100px;
  table-layout:fixed;
  tr{
    &:first-of-type{
      td{
        border-radius: 16px 16px 0 0 ;
        & > div{
          border-bottom : 1px solid rgba(0,0,0,0.04)
        }
      }
    }
    &.hidden{
      td > *{
        overflow:hidden;
        max-height:0;
        height:100%;
      }
    }
    td{
      vertical-align:top;
      &.selected{
        background: #fff;
        color: rgb(28, 160, 141);
        width:25%;
        transition: all 0.25s;

      }
      &:not(.selected){
        background: rgba(0,0,0,0.02);
        width:25%;
        transition: all 0.25s;
      }
      & > *{
        max-height:600px;
        height:100%;
      }
    }
  }

}

</style>

<template>
  <Card
    class="card-toggle"
    :class="[{active: bindIsSelected}, fontSize]"
    :bordered="false"
    :dis-hover="true"
  >
    <span slot="title">
      <i v-if="titleIcon === 'info'"
         class="fas fa-info text-info"
      />
      <i v-if="titleIcon === 'success'"
         class="fas fa-check text-success"
      />
      <i v-if="titleIcon === 'warning'"
         class="fas fa-exclamation-circle text-warning"
      />
      <i v-if="titleIcon === 'error'"
         class="fas fa-exclamation-triangle text-error"
      />
      {{ title }}
    </span>

    <p v-if="!loading">
      <span class="value">{{ data.length }}</span>
      <span v-if="baseData?.length < data.length"
            class="trend"
            :style="{'vertical-align': 'bottom',color: moreIsBetter === true ? '#6BAAD8' : '#FF7850'}"
      >
        &nbsp;<i class="fas fa-arrow-up" />+{{ data.length - baseData?.length }}
      </span>
      <span v-if="baseData?.length > data.length"
            class="trend"
            :style="{'vertical-align': 'top',color: moreIsBetter === false ? '#6BAAD8' : '#FF7850'}"
      >
        &nbsp;<i class="fas fa-arrow-down" />
        {{ data.length - baseData?.length }}
      </span>
    </p>

    <Spin v-else>
      <Icon
        type="ios-loading"
        size="26"
        class="demo-spin-icon-load"
      />
    </Spin>

    <p class="description"
       v-html="description"
    />
  </Card>
</template>


<script>
import { mapGetters } from 'vuex'
import { eventBus } from '@/main'
export default {
  name: 'DeclarationVendorWidget',
  props: {
    fontSize: {
      type: [String],
      note: 'fontsize : small, medium, large, x-large',
      default: 'medium'
    },
    isLoading: {
      type: Boolean,
      note: 'if the endpoint call is loaded',
      default: false
    },
    data: {
      type: [Boolean, Array],
      note: 'The vendor data',
      default: () => false
    },
    baseData: {
      type: [Boolean, Array],
      note: 'The vendor data',
      default: () => false
    },
    moreIsBetter: {
      type: Boolean,
      note: 'is more than base data good ?',
      default: () => true
    },
    title: {
      type:String,
      note: 'widget title',
      default: ''
    },
    titleIcon: {
      type: [Boolean, String],
      note: 'what info level icon ?',
      default: false
    },
    description: {
      type:String,
      note: 'content description',
      default: ''
    },
    isSelected: {
      type: Boolean,
      note: 'If the widget is selected',
      default: false
    },
    nextWidget: {
      type: Boolean,
      note: 'IF this the next widget',
      default: false
    },
    refName: {
      type: String,
      note: 'Component name',
      default: ''
    }
  },
  watch: {
    isSelected: {
      deep: true,
      immediate: true,
      handler: function (val){
        this.bindIsSelected = val
      }
    },
    data: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.vendors = val
      }
    },
    isLoading: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.loading = val
      }
    }
  },
  data () {
    return {
      bus: eventBus,
      bindIsSelected: this.isSelected,
      loading: this.isLoading,
      vendors: this.data
    }
  },
  methods: {
    titleFromRefName (ref) {
      if (ref === 'totalDeclaredNext') return 'Declared in this vendor list project'
      if (ref === 'nonDeclaredActiveNext') return 'Active but not declared in this vendor list project'
      if (ref === 'totalDetectedActiveNext') return 'Active and declared in this vendor list project'
      if (ref === 'declaredNonActiveNext') return 'Inactive but declared in this vendor list project'
      return ''
    }
  },
  computed: {
    ...mapGetters({
      baseVendorListState: 'vendor/baseVendorListState',
      draftVendorList: 'vendor/draftVendorList',
      vendorList: 'vendor/vendorList',
      declaredVendors: 'vendor/declaredVendors'
    }),
    shouldDisplayDot () {
      if (this.refName !== 'totalDeclaredNext') return false
      return true
    },
    actualDeclared () {
      return this.draftVendorList.filter(vendor => vendor.declared_in_vl).length
    },
    shouldDisplayArrow () {
      return this.draftVendorList.filter(elm => elm.declared_in_vl).length || this.draftVendorList.length < this.vendorList.filter(elm => elm.detected_in_cmp)
    },
    shouldDisplayDownArrow () {
      const notDetected = this.vendorList.filter(elm => !elm.detected_in_cmp).length
      const acutalNotDetected = this.vendors.length
      const { amountDeclared } = this.baseVendorListState
      const { refName, actualDeclared } = this
      if (refName === "nonDeclaredActiveNext") {
        if (notDetected > acutalNotDetected) return true
        return false
      }
      if (refName === "totalDeclaredNext") {
        if (actualDeclared > amountDeclared) return false
        return false
      }
      return null
    }
  }

}
</script>
<style lang="scss">
.card-toggle{
  background:transparent;
  cursor: pointer;
  height: 100%;
  position:relative;
  transition: all 0.25s;
  &.small > .ivu-card-body{
    font-size: 10px;
  }
  &.medium > .ivu-card-body{
    font-size: 12px;
    font-weight: 200;
  }
  &.large > .ivu-card-body{
    font-size: 20px;
    font-weight: 200;
  }
  &.x-large > .ivu-card-body{
    font-size: 48px;
    font-weight: 100;
  }
  .ivu-card-head {
    //border-top: 1px solid rgba(0,0,0,0.04);
    border-bottom: 0;
  }
  .ivu-card-body {
    bottom: 0;
    box-sizing: border-box;
    text-align: center;
    color: inherit;
    .value{
      font-size: 2em;
    }
    .trend {
      font-size: 14px;
      font-weight: 400;
    }
    .description{
      display:none;
      border-radius:8px;
      text-align:left;
      font-size: 12px;
      font-weight: 400;
      position: absolute;
      left: 0;
      background: rgba(70, 76, 91, .9);
      padding: 16px 8px 16px 16px;
      margin: 8px;

      color: #fff;
      z-index: 990;
    }
    &:hover{
      .description{
        display:block;
        width:calc(100% - 16px);
        &:empty{
          display:none;
        }
        &:before{
          content:'';
          display:inline-block;
          position: absolute;
          top:-5px;
          left: 50%;
          transform: translate(-50%, -50%);
          height : 0;
          width : 0;
          border-right : 8px solid transparent;
          border-bottom : 10px solid rgba(70, 76, 91, .9);
          border-left : 8px solid transparent;
        }

      }
    }
  }
}
</style>

<template>
  <div class="contentWrapper">
    <Alert type="error"
           show-icon
           v-if="trackerIsFromTcfVendor"
    >
      This vendor has declared purposes and features through IAB TCF v2 under vendor ID {{ trackerProps.vendor.tcfv2_id }}.
      <br>
      We recommend <u>to not</u> exempt trackers belonging to IAB TCF Vendors.
    </Alert>
    <br>
    <div>
      <span>Non Exempted</span>
      <i-switch
        class="switch-separator"
        v-model="activeSwitch"
        @on-change="toggleSwitch"
      />
      <span
        :class="{ active: activeSwitch }"
      >
        Exempted
      </span>
    </div>
    <br>
    <div v-if="activeSwitch">
      <p>
        In order to qualify this tracker as exempted you hereby declare this
        tracker as belonging to one of the following category
      </p>
      <br>
      <p>
        If you need more information about the tracker exemption, we recommend you
        to go to this help center page.
      </p>
      <br>
      <Row
        style="align-items: center"
        v-for="exemptedRadioList of constants.exemptedRadioLists"
        :key="exemptedRadioList.key"
      >
        <Col span="2">
          <Radio
            v-model="exemptedRadioBinding[exemptedRadioList.key]"
            :value="exemptedRadioList.key"
            @on-change="handleRadioClick(exemptedRadioList.key)"
          />
        </Col>
        <Col span="22">
          <div>
            <h6>{{ exemptedRadioList.title }}</h6>
            <p>
              {{ exemptedRadioList.description }}
            </p>
          </div>
          <br>
        </Col>
      </Row>
    </div>
    <br>
    <Button
      type="primary"
      @click="handleAddCookieToExemption"
      style="width:150px; display:block;text-align:center;margin:0 auto;"
    >
      Save and close
    </Button>
  </div>
</template>

<script>
import exp from 'constants'
import { EXEMPTED_RADIO_LIST } from "../constants"
export default {
  name: "Exemption",
  props: {
    trackerProps: {
      note: 'the clicked tracker',
      default: null,
      type: Object
    }
  },
  data: function () {
    return {
      activeSwitch: false,
      selectedTracker: null,
      selectedRadio: "",
      exemptedRadioBinding: {
        user_choice_storage: true, // 1
        authentification: false, // 2
        shopping_basket_storage: false, // 3
        personalization: false,
        load_balancing: false,
        access_measurement: false,
      },
      constants: {
        exemptedRadioLists: EXEMPTED_RADIO_LIST,
      },
    }
  },
  computed: {
    trackerIsFromTcfVendor(){
      return this.trackerProps.vendor?.tcfv2_id
    },
    shouldDipslayQualify () {
      if (this.trackerProps.is_exempted === 1) return false
      if (this.activeSwitch) return false
      return true
    }
  },

  watch: {
    trackerProps:{
      deep:true,
      immediate: true,
      handler: function (val, _) {
        this.selectedRadio = ""
        this.activeSwitch = !!val?.exempted || !!val?.is_exempted
        this.selectedTracker = val
        this.exemptedRadioBinding =  {
          user_choice_storage: true, // 1
          authentification: false, // 2
          shopping_basket_storage: false, // 3
          personalization: false,
          load_balancing: false,
          access_measurement: false,
        }
        this.applyExemptionIfExist()
      }
    },
  },
  methods: {
    applyExemptionIfExist () {
      const isExemption = this?.trackerProps?.exemption_declarations && this.trackerProps.exemption_declarations.length
      if (isExemption) {
        const keys = Object.keys(this.exemptedRadioBinding)
        const exemptionBinded = this.trackerProps.exemption_declarations[0] -1
        console.log(keys, exemptionBinded)
        this.exemptedRadioBinding =  {
          user_choice_storage: false, // 1
          authentification: false, // 2
          shopping_basket_storage: false, // 3
          personalization: false,
          load_balancing: false,
          access_measurement: false,
        }
        this.exemptedRadioBinding[keys[exemptionBinded]] = true
      }
    },
    handleAddCookieToExemption() {
      let exemption = []
      for (let i =0; i < Object.values(this.exemptedRadioBinding).length; i++) {
        if (Object.values(this.exemptedRadioBinding)[i] === true) exemption.push(i+1)
      }
      if (this.activeSwitch === false) exemption = []
      this.$emit('exemption-sent', { exemption })
    },

    toggleSwitch(status) {
      this.activeSwitch = status
    },
    handleRadioClick(key) {
     
      const { exemptedRadioBinding } = this
      this.selectedRadio = key

      console.log('selectedRadio', this.selectedRadio)

      for (const [k, val] of Object.entries(exemptedRadioBinding)) {
        if (key === k) {
          exemptedRadioBinding[k] = true
        } else {
          exemptedRadioBinding[k] = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
.active {
  color: #000;
  font-weight: 700;
}

.switch-separator {
    margin: 0 15px;
}
</style>
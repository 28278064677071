var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cookie-policy"
  }, [_c('Button', {
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": function click($event) {
        _vm.showFinishExportModal = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.constants.TEXTS.EXPORT_QUALIFIED) + " ")]), _c('Button', {
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "type": "info",
      "size": "large"
    },
    on: {
      "click": function click($event) {
        _vm.showOnboardingModal = true;
      }
    }
  }, [_vm._v(" Guide ")]), _c('h1', [_vm._v("Cookie Policy Management")]), _c('br'), !_vm.isLoading ? _c('div', [_c('table', {
    staticClass: "cookielistactions-table"
  }, [_c('tr', [_c('td', {
    class: _vm.getSeletedCardKey === _vm.constants.WIDGET_KEYS.total ? 'selected' : '',
    attrs: {
      "rowspan": "2"
    }
  }, [_c('DeclarationVendorWidget', {
    ref: "total",
    attrs: {
      "is-loading": _vm.isLoading,
      "data": _vm.getObservedCookiesPolicyListByState.total,
      "title": "Active trackers",
      "title-icon": "info",
      "font-size": _vm.getDraftCookiesPolicyListByState.qualified.length > 0 ? 'x-large' : 'large',
      "description": "Total number of active trackers detected on your website."
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget(_vm.constants.WIDGET_KEYS.total);
      }
    }
  })], 1), _c('td', {
    class: _vm.getSeletedCardKey === _vm.constants.WIDGET_KEYS.qualified ? 'selected' : ''
  }, [_c('DeclarationVendorWidget', {
    ref: "qualified",
    attrs: {
      "is-loading": _vm.isLoading,
      "data": _vm.getObservedCookiesPolicyListByState.qualified,
      "title": "Qualified trackers",
      "title-icon": "success",
      "font-size": _vm.getDraftCookiesPolicyListByState.qualified.length > 0 ? 'large' : 'large',
      "description": "Trackers already qualified."
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget(_vm.constants.WIDGET_KEYS.qualified);
      }
    }
  })], 1), _c('td', {
    class: _vm.getSeletedCardKey === _vm.constants.WIDGET_KEYS.unqualified ? 'selected' : ''
  }, [_c('DeclarationVendorWidget', {
    ref: "total",
    attrs: {
      "is-loading": _vm.isLoading,
      "data": _vm.getObservedCookiesPolicyListByState.unqualified,
      "title": "Unqualified trackers",
      "title-icon": "error",
      "font-size": _vm.getDraftCookiesPolicyListByState.unqualified.length > 0 ? 'large' : 'large',
      "description": "Unqualified trackers requiring declaration."
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget(_vm.constants.WIDGET_KEYS.unqualified);
      }
    }
  })], 1), _c('td', {
    class: _vm.getSeletedCardKey === _vm.constants.WIDGET_KEYS.exempted ? 'selected' : ''
  }, [_c('DeclarationVendorWidget', {
    ref: "total",
    attrs: {
      "is-loading": _vm.isLoading,
      "data": _vm.getObservedCookiesPolicyListByState.exempted,
      "title": "Exempted trackers",
      "title-icon": "info",
      "font-size": _vm.getDraftCookiesPolicyListByState.exempted.length > 0 ? 'large' : 'large',
      "description": "Detected exempted."
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget(_vm.constants.WIDGET_KEYS.exempted);
      }
    }
  })], 1)]), _c('tr', {
    class: _vm.getDraftCookiesPolicyListByState.qualified.length > 0 ? '' : 'hidden'
  }, [_c('td', {
    class: _vm.getSeletedCardKey === _vm.constants.WIDGET_KEYS.qualified ? 'selected' : ''
  }, [_c('DeclarationVendorWidget', {
    attrs: {
      "next-widget": true,
      "data": _vm.getDraftCookiesPolicyListByState.qualified,
      "base-data": _vm.getObservedCookiesPolicyListByState.qualified,
      "more-is-better": true,
      "title": "Qualified trackers for Cookie Policy project",
      "font-size": "large"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget(_vm.constants.WIDGET_KEYS.qualified);
      }
    }
  })], 1), _c('td', {
    class: _vm.getSeletedCardKey === _vm.constants.WIDGET_KEYS.unqualified ? 'selected' : ''
  }, [_c('DeclarationVendorWidget', {
    attrs: {
      "next-widget": true,
      "data": _vm.getDraftCookiesPolicyListByState.unqualified,
      "base-data": _vm.getObservedCookiesPolicyListByState.unqualified,
      "more-is-better": false,
      "title": "Trackers not qualified for this Cookie Policy project",
      "font-size": "large"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget(_vm.constants.WIDGET_KEYS.unqualified);
      }
    }
  })], 1), _c('td', {
    class: _vm.getSeletedCardKey === _vm.constants.WIDGET_KEYS.exempted ? 'selected' : ''
  }, [_c('DeclarationVendorWidget', {
    attrs: {
      "next-widget": true,
      "data": _vm.getDraftCookiesPolicyListByState.exempted,
      "base-data": _vm.getObservedCookiesPolicyListByState.exempted,
      "more-is-better": true,
      "title": "Exempted trackers in this Cookie Policy project",
      "font-size": "large"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget(_vm.constants.WIDGET_KEYS.exempted);
      }
    }
  })], 1)]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_vm.getSeletedCardKey === _vm.constants.WIDGET_KEYS.total ? _c('Card', {
    attrs: {
      "bordered": false,
      "dis-hover": true
    }
  }, [_c('br'), _c('h1', [_vm._v("Total number of active trackers detected on your website")]), _c('br'), _c('Row', [_c('Col', {
    attrs: {
      "md": 14,
      "sm": 24
    }
  }, [_c('div', [_c('p', [_vm._v("Displays all trackers that were detected on your website for the selected period.")])])]), _c('Col', {
    attrs: {
      "md": 10,
      "sm": 24
    }
  }, [_c('div', [_c('Button', {
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": function click($event) {
        _vm.showDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.constants.TEXTS.AUTO_QUALIFY_TCF) + " ")])], 1)])], 1)], 1) : _vm._e(), _vm.getSeletedCardKey === _vm.constants.WIDGET_KEYS.qualified ? _c('Card', {
    attrs: {
      "bordered": false,
      "dis-hover": true
    }
  }, [_c('br'), _c('h1', [_vm._v("Trackers already qualified")]), _c('br'), _c('Row', [_c('Col', {
    attrs: {
      "md": 16,
      "sm": 24
    }
  }, [_c('div', [_c('p', [_vm._v("Trackers already prepared and qualified on your Cookie Policy Project")])])]), _c('Col', {
    attrs: {
      "md": 8,
      "sm": 24
    }
  }, [_c('div', [_c('Button', {
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": function click($event) {
        _vm.showDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.constants.TEXTS.AUTO_QUALIFY_TCF) + " ")])], 1)])], 1)], 1) : _vm._e(), _vm.getSeletedCardKey === _vm.constants.WIDGET_KEYS.unqualified ? _c('Card', {
    attrs: {
      "bordered": false,
      "dis-hover": true
    }
  }, [_c('br'), _c('h1', [_vm._v("Unqualified trackers requiring declaration")]), _c('br'), _c('Row', [_c('Col', {
    attrs: {
      "md": 16,
      "sm": 24
    }
  }, [_c('div', [_c('p', [_vm._v(" In order to generate the cookie policy you need to either associate purposes or exempt these trackers ")])])]), _c('Col', {
    attrs: {
      "md": 8,
      "sm": 24
    }
  }, [_c('div', [_c('Button', {
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": function click($event) {
        _vm.showDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.constants.TEXTS.AUTO_QUALIFY_TCF) + " ")])], 1)])], 1)], 1) : _vm._e(), _vm.getSeletedCardKey === _vm.constants.WIDGET_KEYS.exempted ? _c('Card', {
    attrs: {
      "bordered": false,
      "dis-hover": true
    }
  }, [_c('br'), _c('h1', [_vm._v("Exempted trackers")]), _c('br'), _c('Row', [_c('Col', {
    attrs: {
      "md": 16,
      "sm": 24
    }
  }, [_c('div', [_c('p', [_vm._v(" Trackers described here can be of two sources, Agnostik exempted trackers and publisher exempted trackers. ")]), _c('p', [_vm._v(" You cannot un-exempt an Agnostik exempted trackers ")])])]), _vm.userHasRole('super-admin') ? _c('Col', {
    attrs: {
      "md": 8,
      "sm": 24
    }
  }, [_c('div', [_c('Button', {
    attrs: {
      "type": "warning",
      "size": "large"
    },
    on: {
      "click": function click($event) {
        _vm.showDeclareExemptedtrackersModal = true;
      }
    }
  }, [_vm._v(" Apply property trackers exemptions to incoming data ")])], 1)]) : _vm._e()], 1)], 1) : _vm._e()], 1)])]), _c('br'), _c('Modal', {
    attrs: {
      "width": "640px",
      "title": _vm.constants.TEXTS.AUTO_QUALIFY_TCF
    },
    on: {
      "on-ok": _vm.handleDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal,
      "on-cancel": function onCancel($event) {
        _vm.showDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal = false;
      }
    },
    model: {
      value: _vm.showDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal,
      callback: function callback($$v) {
        _vm.showDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal = $$v;
      },
      expression: "showDeclareAllActiveTCFVendorsTrackersInCookielistProjectModal"
    }
  }, [_c('p', [_vm._v("This feature will qualify trackers belonging to identified TCF vendors and add them to the Cookie Policy project")]), _c('p', [_vm._v("Each tracker Purposes will be set to their default vendor TCF settings.")]), _c('br'), _c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" Do note that exempted trackers will not be added ")])], 1), _c('Modal', {
    attrs: {
      "width": "640px",
      "title": "Apply property trackers exemptions"
    },
    on: {
      "on-ok": _vm.handleDeclareExemptedtrackersModal,
      "on-cancel": function onCancel($event) {
        _vm.showDeclareExemptedtrackersModal = false;
      }
    },
    model: {
      value: _vm.showDeclareExemptedtrackersModal,
      callback: function callback($$v) {
        _vm.showDeclareExemptedtrackersModal = $$v;
      },
      expression: "showDeclareExemptedtrackersModal"
    }
  }, [_vm.appliedExemptedTrackers.length == 0 ? _c('div', [_c('p', [_vm._v("By proceeding, the following trackers will be exempted for this property.")]), _c('br'), _c('table', {
    staticClass: "ivu-table ivu-table-default",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('thead', [_c('th', [_vm._v("identifier")]), _c('th', [_vm._v("domain")]), _c('th', [_vm._v("type")])]), _vm._l(_vm.getDeclareCookiesPolicyListByState.exempted, function (tracker) {
    return _c('tr', {
      key: tracker.hash_namehosttype
    }, [_c('td', [_vm._v(_vm._s(tracker.identifier))]), _c('td', [_vm._v(_vm._s(tracker.domain))]), _c('td', [_vm._v(_vm._s(tracker.type))])]);
  })], 2)]) : _c('div', [_c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" The following trackers have been exempted and any new data for this property will apply these exemptions. ")]), _c('br'), _c('table', {
    staticClass: "ivu-table ivu-table-default",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('thead', [_c('th', [_vm._v("identifier")]), _c('th', [_vm._v("domain")]), _c('th', [_vm._v("type")])]), _vm._l(_vm.appliedExemptedTrackers, function (tracker) {
    return _c('tr', {
      key: tracker.hash_namehosttype
    }, [_c('td', [_vm._v(_vm._s(tracker.identifier))]), _c('td', [_vm._v(_vm._s(tracker.domain))]), _c('td', [_vm._v(_vm._s(tracker.type))])]);
  })], 2)], 1)]), _c('CookiePolicyTable', {
    attrs: {
      "is-loading-prop": _vm.isLoading
    }
  }), _c('Modal', {
    staticClass: "vlm-help-modal",
    attrs: {
      "width": "640px",
      "title": "Cookie Policy Guide"
    },
    model: {
      value: _vm.showOnboardingModal,
      callback: function callback($$v) {
        _vm.showOnboardingModal = $$v;
      },
      expression: "showOnboardingModal"
    }
  }, [_c('OnboardingModal', {
    on: {
      "close-onboarding-modal": function closeOnboardingModal($event) {
        _vm.showOnboardingModal = false;
      }
    }
  })], 1)], 1) : _c('div', [_c('Spin', [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "26"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.$t("global.loading")))])], 1)], 1), _c('Modal', {
    staticClass: "vlm-help-modal",
    attrs: {
      "draggable": "",
      "title": "Finish Export",
      "width": "640px"
    },
    model: {
      value: _vm.showFinishExportModal,
      callback: function callback($$v) {
        _vm.showFinishExportModal = $$v;
      },
      expression: "showFinishExportModal"
    }
  }, [_c('FinishExportModal', {
    on: {
      "trigger-download": function triggerDownload($event) {
        return _vm.exportQualifiedTrackers();
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
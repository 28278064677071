var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Row', {
    attrs: {
      "gutter": 1
    }
  }, _vm._l(_vm.topWidgets, function (val, key) {
    return _c('Col', {
      key: key,
      attrs: {
        "span": "6"
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "flex-direction": "column"
      },
      on: {
        "click": function click($event) {
          return _vm.saveSelectedKey(val.key);
        }
      }
    }, [_c('CookiePolicyWidget', {
      attrs: {
        "show-warning": val.showWarning,
        "component-key": val.key,
        "title": val.name
      }
    }), val.key === _vm.constants.WIDGET_KEYS.total ? _c('p', {
      staticStyle: {
        "height": "100%",
        "width": "100%",
        "display": "flex",
        "align-items": "center",
        "justify-content": "space-around"
      }
    }, [_c('strong', [_vm._v(" " + _vm._s(_vm.constants.TEXTS.PROJECTED_COOKIE_POLICY) + " ")])]) : _vm._e(), val.key !== _vm.constants.WIDGET_KEYS.total ? _c('CookiePolicyWidget', {
      attrs: {
        "next-widget": true,
        "show-warning": true,
        "component-key": val.key
      }
    }) : _vm._e()], 1)]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm$selectedTracker, _vm$selectedTracker2, _vm$selectedTracker2$, _vm$selectedTracker3, _vm$selectedTracker3$, _vm$selectedTracker4, _vm$selectedTracker4$, _vm$selectedTracker5, _vm$selectedTracker6, _vm$selectedTracker6$, _vm$selectedTracker7, _vm$selectedTracker7$, _vm$trackerProps, _vm$trackerProps$vend;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Row', {
    staticClass: "contentWrapper",
    staticStyle: {
      "width": "450px",
      "margin": "0 auto"
    }
  }, [_c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("Vendor")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_vm._v(_vm._s((_vm$selectedTracker = _vm.selectedTracker) !== null && _vm$selectedTracker !== void 0 && _vm$selectedTracker.vendor ? _vm.selectedTracker.vendor.name : ""))])]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("Vendor Category")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_vm._v(" " + _vm._s((_vm$selectedTracker2 = _vm.selectedTracker) === null || _vm$selectedTracker2 === void 0 ? void 0 : (_vm$selectedTracker2$ = _vm$selectedTracker2.vendor) === null || _vm$selectedTracker2$ === void 0 ? void 0 : _vm$selectedTracker2$.category_name) + " ")])]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("Vendor Trust Index")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_c('Tag', {
    attrs: {
      "type": "dot",
      "color": _vm.complianceColor[this.vendorVigilanceToLetter((_vm$selectedTracker3 = _vm.selectedTracker) === null || _vm$selectedTracker3 === void 0 ? void 0 : (_vm$selectedTracker3$ = _vm$selectedTracker3.vendor) === null || _vm$selectedTracker3$ === void 0 ? void 0 : _vm$selectedTracker3$.vigilance_rating)]
    }
  }, [_vm._v(_vm._s(this.vendorVigilanceToLetter((_vm$selectedTracker4 = _vm.selectedTracker) === null || _vm$selectedTracker4 === void 0 ? void 0 : (_vm$selectedTracker4$ = _vm$selectedTracker4.vendor) === null || _vm$selectedTracker4$ === void 0 ? void 0 : _vm$selectedTracker4$.vigilance_rating)))])], 1)]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("Domain")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_vm._v(" " + _vm._s((_vm$selectedTracker5 = _vm.selectedTracker) === null || _vm$selectedTracker5 === void 0 ? void 0 : _vm$selectedTracker5.domain) + " ")])]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("1st/3rd Party")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_vm._v(" " + _vm._s(_vm.selectedTracker.is_first_party ? "1st Party" : "3rd Party") + " ")])]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("Type")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_vm._v(" " + _vm._s(_vm.selectedTracker.type) + " ")])]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("Lifetime")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_vm._v(" " + _vm._s(_vm.getCookieAgeInMonthOrDays(_vm.selectedTracker.max_age_seconds)) + " ")])]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("Disclosed In Tcf")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_c('strong', [_vm._v(_vm._s(((_vm$selectedTracker6 = _vm.selectedTracker) === null || _vm$selectedTracker6 === void 0 ? void 0 : (_vm$selectedTracker6$ = _vm$selectedTracker6.vendor) === null || _vm$selectedTracker6$ === void 0 ? void 0 : _vm$selectedTracker6$.tcfv2_id) || ((_vm$selectedTracker7 = _vm.selectedTracker) === null || _vm$selectedTracker7 === void 0 ? void 0 : (_vm$selectedTracker7$ = _vm$selectedTracker7.vendor) === null || _vm$selectedTracker7$ === void 0 ? void 0 : _vm$selectedTracker7$.iabv2Id) || 'No'))])])]), _c('Col', {
    staticStyle: {
      "margin-top": "48px",
      "width": "100%"
    }
  }, [_c('Button', {
    staticStyle: {
      "width": "150px",
      "display": "block",
      "text-align": "center",
      "margin": "0 auto"
    },
    attrs: {
      "type": "warning",
      "disabled": !((_vm$trackerProps = _vm.trackerProps) !== null && _vm$trackerProps !== void 0 && (_vm$trackerProps$vend = _vm$trackerProps.vendor) !== null && _vm$trackerProps$vend !== void 0 && _vm$trackerProps$vend.name)
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('qualify-tracker', {
          tracker: _vm.trackerProps
        });
      }
    }
  }, [_vm._v(" Qualify ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm$baseData, _vm$baseData2, _vm$baseData3, _vm$baseData4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "card-toggle",
    class: [{
      active: _vm.bindIsSelected
    }, _vm.fontSize],
    attrs: {
      "bordered": false,
      "dis-hover": true
    }
  }, [_c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm.titleIcon === 'info' ? _c('i', {
    staticClass: "fas fa-info text-info"
  }) : _vm._e(), _vm.titleIcon === 'success' ? _c('i', {
    staticClass: "fas fa-check text-success"
  }) : _vm._e(), _vm.titleIcon === 'warning' ? _c('i', {
    staticClass: "fas fa-exclamation-circle text-warning"
  }) : _vm._e(), _vm.titleIcon === 'error' ? _c('i', {
    staticClass: "fas fa-exclamation-triangle text-error"
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.title) + " ")]), !_vm.loading ? _c('p', [_c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.data.length))]), ((_vm$baseData = _vm.baseData) === null || _vm$baseData === void 0 ? void 0 : _vm$baseData.length) < _vm.data.length ? _c('span', {
    staticClass: "trend",
    style: {
      'vertical-align': 'bottom',
      color: _vm.moreIsBetter === true ? '#6BAAD8' : '#FF7850'
    }
  }, [_vm._v("  "), _c('i', {
    staticClass: "fas fa-arrow-up"
  }), _vm._v("+" + _vm._s(_vm.data.length - ((_vm$baseData2 = _vm.baseData) === null || _vm$baseData2 === void 0 ? void 0 : _vm$baseData2.length)) + " ")]) : _vm._e(), ((_vm$baseData3 = _vm.baseData) === null || _vm$baseData3 === void 0 ? void 0 : _vm$baseData3.length) > _vm.data.length ? _c('span', {
    staticClass: "trend",
    style: {
      'vertical-align': 'top',
      color: _vm.moreIsBetter === false ? '#6BAAD8' : '#FF7850'
    }
  }, [_vm._v("  "), _c('i', {
    staticClass: "fas fa-arrow-down"
  }), _vm._v(" " + _vm._s(_vm.data.length - ((_vm$baseData4 = _vm.baseData) === null || _vm$baseData4 === void 0 ? void 0 : _vm$baseData4.length)) + " ")]) : _vm._e()]) : _c('Spin', [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "26"
    }
  })], 1), _c('p', {
    staticClass: "description",
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
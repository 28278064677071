var render = function render(){
  var _vm$selectedTracker, _vm$selectedTracker$v, _vm$selectedTracker2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.trigger ? _c('div', [_c('div', {
    staticClass: "tracker-content"
  }, [_c('div', [_c('h1', [_vm._v(_vm._s(_vm.selectedTracker.identifier))]), _c('br'), _c('h6', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(((_vm$selectedTracker = _vm.selectedTracker) === null || _vm$selectedTracker === void 0 ? void 0 : (_vm$selectedTracker$v = _vm$selectedTracker.vendor) === null || _vm$selectedTracker$v === void 0 ? void 0 : _vm$selectedTracker$v.name) || '') + " ")])])]), _c('Tabs', {
    attrs: {
      "value": "PURPOSES"
    },
    model: {
      value: _vm.tabActive,
      callback: function callback($$v) {
        _vm.tabActive = $$v;
      },
      expression: "tabActive"
    }
  }, [_c('TabPane', {
    attrs: {
      "label": "IDENTITY",
      "name": "IDENTITY"
    }
  }, [!((_vm$selectedTracker2 = _vm.selectedTracker) !== null && _vm$selectedTracker2 !== void 0 && _vm$selectedTracker2.vendor_id) ? _c('VendorManualMapping', {
    attrs: {
      "tracker-props": _vm.selectedTracker
    },
    on: {
      "linkToVendor": _vm.refreshCookieData
    }
  }) : _vm._e(), _c('Identity', {
    attrs: {
      "tracker-props": _vm.selectedTracker
    },
    on: {
      "qualify-tracker": _vm.handleApplyAction
    }
  })], 1), _c('TabPane', {
    attrs: {
      "label": "PURPOSES",
      "name": "PURPOSES"
    }
  }, [_c('PurposeList', {
    ref: "cookie-purposes-list",
    attrs: {
      "tracker-props": _vm.selectedTracker
    },
    on: {
      "qualify-tracker": _vm.handleApplyAction,
      "purposes-sent": _vm.handlePurposeChange
    }
  })], 1), _c('TabPane', {
    attrs: {
      "label": "EXEMPTION",
      "name": "EXEMPTION"
    }
  }, [_c('Exemption', {
    attrs: {
      "tracker-props": _vm.selectedTracker
    },
    on: {
      "exemption-sent": _vm.handleApplyAction
    }
  })], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
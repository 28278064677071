import { render, staticRenderFns } from "./DescriptionAlert.vue?vue&type=template&id=8d7ad65e"
import script from "./DescriptionAlert.vue?vue&type=script&lang=js"
export * from "./DescriptionAlert.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports